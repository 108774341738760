/* eslint-disable no-useless-constructor */
import React from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import '../../styles/chat/modal.scss'
import api from '../../config/api'
import axios from 'axios'
import moment from 'moment'
import Cleave from 'cleave.js/react';
import { updateTimeUploadImage } from '../../controllers/chatCtrl'
import usersCtrl from '../../controllers/usersCtrl'
import invoiceCtrl from '../../controllers/invoicesCtrl'
import purchaseCtrl from '../../controllers/purchasesCtrl'
import ordersCtrl from '../../controllers/ordersCtrl'
import * as utils from '../../utils/utils'
const api_usersCtrl = new usersCtrl();
const api_invoiceCtrl = new invoiceCtrl();
const api_purchaseCtrl = new purchaseCtrl();
const api_ordersCtrl = new ordersCtrl();




class ModalManageInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: this.props.showModalInvoice,
            listInvoice: [],
            file: "",
            imgPreviewUrl: "",
            isConfirmInvoice: false,
            isConfirmClick: false,
            errDate: false,
            errTime: false,
            errAmount: false,
            errMsg: false,
            errNoInvoice: false,
            dataInvoice: undefined,
            isShowImageZoom: false,
            totalPriceInvo: 0,
            checkInvoAll: false,
            invoSelect: [],
            customerId: "",
            listPurchase: [],
            radioSelect: "",
            note: "",
            dateTran: "",
            timeTran: "",
            amountTran: "",
            listExpireInvoice: [],
            invoiceSelectData: [],
            invoiceData : []
        };
    }


    autoUserByLineId = async (id, name) => {
        if (this.props.platform == 'mobile') {
            const user = await api_usersCtrl.autoUserByMobile({ displayName: name, mobile: id })
            return user.data.id
        } else {
            const user = await api_usersCtrl.autoUserByLineIdOnly({ displayName: name, lineId: id })
            return user.id
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showModalInvoice) {
            this.listInvo(nextProps.id, nextProps.name)
            this.setState({
                id: nextProps.id,
                name: nextProps.name
            })
        }

        if (nextProps.slip) {
            this.setState({
                imgPreviewUrl: nextProps.slip
            })
        }
    }

    listInvo = async (id, name) => {
        const userId = await this.autoUserByLineId(id, name)
        //// status wait ใช้ เชค "ยังไม่ได้อัพโหลดสลิป" 
        const invoice = await api_invoiceCtrl.listInvoiceByUserId(userId, "wait") ///เมื่อข้อมูลครบ แก้ตรงไอดีนี้ [wait, waitDelivery, completed]
        const listPurchase = await api_purchaseCtrl.ListPurchasesByUserId(userId, "confirm")
        const invoiceExpire = await api_invoiceCtrl.listInvoiceByUserId(userId, "expired")
        
        // const listPurchaseWait = await api_purchaseCtrl.ListPurchasesByUserId(userId, "wait")

        const arrInvoice = []
        let totalPriceInvo = 0
        const allInvoId = []
        const invoiceSelectData = []

        await Promise.all(
            invoice.map(payment => {
                // if (!payment.purchase) {
                totalPriceInvo += payment.priceTotal
                arrInvoice.push({
                    invoNo: payment.invoiceNo,
                    menu: payment.order,
                    totalPrice: payment.priceTotal,
                    status: payment.paymentStatus,
                    purchaseId: payment.purchaseId,
                    userId: payment.userId,
                    id: payment.id,
                    allDataInvoice: payment,
                })
                if(payment.invoiceNo != null){
                    allInvoId.push(payment.id)
                    invoiceSelectData.push({...payment,invoNo:payment.id})
                }
               
                // }
            })
        )

        const listPc = await Promise.all(
            listPurchase.filter(item => item.invoice.length > 0).map(purchase => {
                return ({
                    transid: purchase.transid,
                    invoice: purchase.invoice,
                    priceTotal: purchase.priceTotal,
                    id: purchase.id
                })
            })
        )

        const expiredInvo = await Promise.all(
            invoiceExpire && invoiceExpire.map(expireInvo => {
                return ({
                    invoNo: expireInvo.invoiceNo,
                    menu: expireInvo.order,
                    totalPrice: expireInvo.priceTotal,
                    status: expireInvo.paymentStatus,
                    userId: expireInvo.userId,
                    id: expireInvo.id
                })
            })
        )

        // await Promise.all(
        //     listPurchaseWait.map(async payment => {
        //         if(payment.invoice){
        //             await Promise.all(
        //                 payment.invoice.map(payment => {
        //                     if (!payment.purchase) {
        //                         totalPriceInvo += payment.priceTotal
        //                         arrInvoice.push({
        //                             invoNo: payment.invoiceNo,
        //                             menu: payment.order,
        //                             totalPrice: payment.priceTotal,
        //                             status: payment.paymentStatus,
        //                             purchaseId: payment.purchaseId,
        //                             userId: payment.userId,
        //                             id: payment.id
        //                         })
        //                         allInvoId.push(payment.id)
        //                     }
        //                 })
        //             )

        //         }
        //     })
        // )

        this.setState({
            listInvoice: arrInvoice,
            totalPriceInvo: totalPriceInvo,
            allInvoId: allInvoId,
            customerId: userId,
            listPurchase: listPc,
            listExpireInvoice: expiredInvo,
            invoiceData: invoiceSelectData
        })
    }

    toggleModalPreview = () => {
        this.clearData()
        this.props.closeModalInvoice()
    }

    handleImageChange = async (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            const file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imgPreviewUrl: reader.result
                })
            }

            reader.readAsDataURL(file)
        }
    }

    cancelSendImg = () => {
        this.setState({
            file: "",
            imgPreviewUrl: "",
            isShowImageZoom: false
        })
    }

    checkDate = () => {
        if (this.state.dateTran) {
            return true
        } else {
            this.setState({
                errDate: true
            })
            return false
        }
    }

    checkTime = () => {
        if (this.state.timeTran) {
            return true
        } else {
            this.setState({
                errTime: true
            })
            return false
        }
    }

    checkAmount = () => {
        if (this.state.amountTran) {
            return true
        } else {
            this.setState({
                errAmount: true
            })
            return false
        }
    }

    onClickSubmit = async () => {

        const { dateTran, timeTran, amountTran } = this.state
        if (dateTran.trim().length == 0 || timeTran.trim().length == 0 || amountTran.trim().length == 0) {
            if (dateTran.trim().length == 0) {
                this.setState({
                    errDate: true
                })
            }
            if (timeTran.trim().length == 0) {
                this.setState({
                    errTime: true
                })
            }
            if (amountTran.trim().length == 0) {
                this.setState({
                    errAmount: true
                })
            }
            return
        }
        if (!utils.validateDateRegular.test(dateTran)) {
            this.setState({
                errDate: true
            })
            return
        }

        if (this.state.file || this.props.slip) {


            if (this.state.invoSelect.length > 0 || this.state.radioSelect) {

                this.setState({
                    isConfirmClick: true
                })

                let fileName = ""
                let pathName = ""
                let reqUrl = ""

                if (this.props.slip) {
                    fileName = this.props.slip.split('/')[this.props.slip.split('/').length - 1]
                    pathName = this.props.slip

                } else {
                    const path = `${api.MEDIA_API}/upload`
                    const formData = new FormData()
                    formData.append('file', this.state.file);
                    formData.append('serviceKey', api.MEDIA_SERVICE_KEY);
                    formData.append('secretKey', api.MEDIA_SECRET_KEY);

                    const upload = await axios.post(path, formData)
                    const urlImg = upload.data.fileName

                    fileName = upload.data.fileName
                    pathName = `https://thumb.izcene.com/saranros/image/${urlImg}`

                }

                if (this.state.radioSelect == "wait") {
                    const dataReqPayUrl = {
                        userId: this.state.customerId,
                        invoiceId: this.state.invoSelect,
                        methodPayment: "transfer"
                    }

                    reqUrl = await api_purchaseCtrl.requestPayUrl(dataReqPayUrl)
                }

                const data = {
                    purchaseId: this.state.radioSelect == "wait" ? reqUrl.purchaseId : this.state.radioSelect,
                    userId: this.state.customerId,
                    transPayment: this.state.dateTran && this.state.timeTran ? `${this.state.dateTran.split('/')[2] - 543 > 0 ? this.state.dateTran.split('/')[2] - 543 : moment().format('YYYY')}-${this.state.dateTran.split('/')[1]}-${this.state.dateTran.split('/')[0]}T${this.state.timeTran}:00.404Z` : null,
                    priceNet: this.state.amountTran ? this.state.amountTran : null,
                    pathImg: pathName,
                    fileName: fileName,
                    originalFileName: fileName + "ImageSlip",
                    note: this.state.note
                }

                const confirm = await api_purchaseCtrl.confirmPayment(data)

                if (this.props.idMsg) {
                    updateTimeUploadImage({
                        id: this.props.idMsg,
                        uploadTime: Date.now()
                    })
                    this.props.refresh(this.props.id)
                }

                this.setState({
                    file: "",
                    imgPreviewUrl: "",
                    isConfirmInvoice: true,
                    isConfirmClick: false,
                    errMsg: false
                })
            } else {
                this.setState({
                    errMsg: true
                })
            }

        } else {
            alert("โปรดใส่สลิป")
        }
    }

    reConfirmInvoice = () => {
        this.clearData()
        this.listInvo(this.props.id, this.props.name)
    }

    onClickSelectInvoice = (data) => {
        const arrSelect = [...this.state.invoSelect]
        const arraySelect = [...this.state.invoiceSelectData]
        if (arrSelect.indexOf(data.id) === -1) {
            arrSelect.push(data.id)
            arraySelect.push(data)
            this.setState({
                invoSelect: arrSelect,
                errMsg: false,
                radioSelect: "wait",
                invoiceSelectData: arraySelect
            })
        } else {
            const arrayFind = arraySelect.findIndex((item,idx) => item.id == data.id)
            arrSelect.splice(arrSelect.indexOf(data.id), 1)
            arraySelect.splice(arrayFind,1)
            this.setState({
                invoSelect: arrSelect,
                errMsg: false,
                checkInvoAll: false,
                radioSelect: "",
                invoiceSelectData: arraySelect
            })
        }
       
        const findErrInvoiceNo = arraySelect.find((item,idx) => item.invoNo == null)
        
        if(findErrInvoiceNo == null){
            this.setState({
                errNoInvoice: false,
                invoiceSelectData: arraySelect
            })
        } else {
            this.setState({
                errNoInvoice: true,
                invoiceSelectData: arraySelect
            })
        }
        if(arraySelect.length == 0){
            this.setState({
                errNoInvoice: false,
                invoiceSelectData: arraySelect
            })
        }
    }

    clearData = () => {
        this.setState({
            listInvoice: [],
            file: "",
            imgPreviewUrl: "",
            isConfirmInvoice: false,
            isConfirmClick: false,
            dateTran: "",
            timeTran: "",
            amountTran: "",
            errDate: false,
            errTime: false,
            errAmount: false,
            errMsg: false,
            totalPriceInvo: 0,
            checkInvoAll: false,
            invoSelect: [],
            customerId: "",
            listPurchase: [],
            radioSelect: "",
            errNoInvoice: false,
            invoiceSelectData: [],
            invoiceData : [],
            errNoInvoice: false,
            note: "",
            listExpireInvoice: [],
        })
    }

    closeModalPreview = () => {
        this.setState({
            isShowImageZoom: false
        })
    }

    showModalPreview = () => {
        this.setState({
            isShowImageZoom: true,
            imagePreview: this.state.imgPreviewUrl
        })
    }

    onCheckInvoiceData = async () => {
        var statusUpdateInvoice = true
        var invoice = this.state.listInvoice
        invoice.map((item, idx) => {
            if (item.invoNo == null) {
                if (item.allDataInvoice.address == null) {
                    statusUpdateInvoice = false
                    invoice = [
                        ...invoice.slice(0, idx),
                        {
                            ...invoice[idx],
                            statusUpdateInvoice: false
                        },
                        ...invoice.slice(idx + (1))
                    ]
                }
            }
            this.setState({
                listInvoice: invoice
            })
        })

        if (statusUpdateInvoice) {
            const stateCreateOrder = {
                stateCreateOrder: {
                    userCustomer: {
                        id: this.state.customerId
                    }
                }
            }
            const updateInvoice = await api_ordersCtrl.updateInvoiceByUserId(stateCreateOrder)
            this.listInvo(this.state.id, this.state.name)
        } else {

        }
    }

    deleteInvoiceProblem = async (invoice) => {
        const dataDelete = {
            orderId: invoice.allDataInvoice.order[0].id,
            invoiceId: invoice.allDataInvoice.order[0].invoiceId
        }
        const result = await api_ordersCtrl.deleteOrdertMulti(dataDelete)
        this.listInvo(this.state.id, this.state.name)
    }

    checkInvoiceError = () => {
       const data = this.state.listInvoice.find((item,idx) => item.invoNo == null)
       if(data && data){
        return true
       } else {
           return false
       }
    }


    render() {
        return (
            <Modal
                isOpen={this.props.showModalInvoice}
                // toggle={this.toggleModalPreview}
                className="modal-large" >
                {
                    this.state.isConfirmInvoice ?
                        <ModalBody className="msg">
                            <label className="icon"><i className="icon-ok-circled" /></label>
                            <label className="message">อัพโหลดสลิปสำเร็จ</label>
                            <button className="btn btn-white" onClick={this.reConfirmInvoice}>อัพโหลดใหม่</button>
                            <button className="btn btn-primary-sr" onClick={this.toggleModalPreview}>ปิด</button>
                        </ModalBody>
                        :
                        <ModalBody>
                            <div className="div-order">
                                <div className="div-upload-slip">
                                    <h3>อัพโหลดสลิปโอนเงิน</h3>
                                    {
                                        this.state.imgPreviewUrl ?
                                            <div onMouseEnter={this.showModalPreview} onMouseLeave={this.closeModalPreview} className="box-img">
                                                <img className="img-slip" src={this.state.imgPreviewUrl} />
                                                <button className="btn btn-close" type="button" onClick={this.cancelSendImg}><i className="icon-close" /></button>
                                            </div>
                                            :
                                            <button className="btn btn-attach-modal" type="button"><i className="icon-close" />
                                                <input type="file" onChange={(e) => this.handleImageChange(e)} />
                                            </button>
                                    }
                                    <div className="wrap-form">

                                        <FormGroup>
                                            <label>วันที่โอนเงิน ( ต.ย. 15/12/2562 )</label>
                                            <Cleave
                                                placeholder="วว/ดด/ปปปป"
                                                options={{ date: false, datePattern: ['d', 'm', 'Y'] }}
                                                className={this.state.errDate ? "invalid form-control" : "valid form-control"}
                                                onChange={(e) => this.setState({ dateTran: e.target.value, errDate: false })}
                                            />
                                            {this.state.errDate ? <span className="color-red">กรุณากรอกวันที่</span> : ""}
                                        </FormGroup>


                                        <FormGroup>
                                            <label>เวลาที่โอนเงิน ( ต.ย. 14:23 )</label>
                                            <Cleave
                                                placeholder="00:00"
                                                options={{ time: true, timePattern: ['h', 'm'] }}
                                                className={this.state.errTime ? "invalid form-control" : "valid form-control"}
                                                // onFocus={this.onCreditCardFocus}
                                                onChange={(e) => this.setState({ timeTran: e.target.value, errTime: false })}
                                            />
                                            {this.state.errTime ? <span className="color-red">กรุณากรอกเวลา</span> : ""}
                                        </FormGroup>


                                        <FormGroup>
                                            <label>จำนวนเงินที่โอน (บาท)</label>
                                            <Input type="number"
                                                placeholder="0.00"
                                                name="amount"
                                                autoComplete="nope"
                                                className={this.state.errAmount ? "invalid" : "valid"}
                                                onChange={(e) => this.setState({ amountTran: e.target.value, errAmount: false })}
                                            />
                                            {this.state.errAmount ? <span className="color-red">กรุณากรอกจำนวนเงิน</span> : ""}
                                        </FormGroup>

                                        <FormGroup>
                                            <label>โน้ต (ถ้ามี)</label>
                                            <Input type="textarea"
                                                name="note"
                                                autoComplete="nope"
                                                className=""
                                                onChange={(e) => this.setState({ note: e.target.value })}
                                            />
                                        </FormGroup>
                                    </div>

                                </div>

                                {
                                    this.state.isShowImageZoom ?
                                        <div className="box-preview">
                                            <img src={this.state.imagePreview} />
                                            {/* <img style={{ width: "90%" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRySz_cptytnZ-xWC-qqQG02j7IfbTZXKTcDkFQU8cv4BXQSEYw" /> */}
                                        </div>
                                        :
                                        null
                                }

                                <div className="box-invoice">
                                    <button className="btn-cloes btn" onClick={this.toggleModalPreview}>
                                        <i className="icon-close" />
                                    </button>
                                    <div className="header-invoice">
                                        <h3>เลือกออเดอร์เพื่อยืนยันการชำระเงิน</h3>
                                    </div>
                                    <div className="wrap-invoice">

                                        {
                                            this.state.listInvoice.length > 0 ?
                                                <FormGroup className="wait">
                                                    <Input id="invoiceSelectAll"
                                                        className="radio-custom-modal _left-30"
                                                        type="radio"
                                                        name="selectInvoice"
                                                        checked={this.state.checkInvoAll || this.state.radioSelect === "wait"}
                                                        onChange={() => this.setState({ checkInvoAll: !this.state.checkInvoAll, invoSelect: this.state.checkInvoAll ? [] : this.state.allInvoId, errMsg: false, radioSelect: "wait",invoiceSelectData: this.state.checkInvoAll ? [] : this.state.invoiceData, errNoInvoice: false })}
                                                    />
                                                    <label htmlFor={"wait"} className="radio-custom-modal-label">
                                                        <div className="wrap-topic">
                                                            <label className="topic-text">รหัสการชำระเงิน -</label>
                                                            <label className="text-status"><i className="wait icon-circle" />ยังไม่ได้แจ้งชำระ</label>
                                                        </div>
                                                        {
                                                            this.state.listInvoice.map((invoice, index) =>
                                                                <div className="box-order-wait" key={"invoices" + index}>
                                                                    <div className="row-order">
                                                                        <FormGroup key={invoice.invoNo + index}>
                                                                            <Input id={index}
                                                                                className="checkbox-custom-modal"
                                                                                type="checkbox"
                                                                                name="selectInvoice"
                                                                                checked={this.state.invoSelect.includes(invoice.id) ? true : false}
                                                                                onChange={() => this.onClickSelectInvoice(invoice)}
                                                                            />
                                                                            <label htmlFor={index} className="checkbox-custom-modal-label" name="selectInvoice">
                                                                                <div className="topic-order">
                                                                                    <div className="row-header">
                                                                                        <label className="text-order _blue"> คำสั่งซื้อรหัส {invoice.invoNo} </label>
                                                                                        <label className="text-sum _blue">{invoice.totalPrice} บาท</label>
                                                                                    </div>
                                                                                    {
                                                                                        invoice.menu.map(menu =>
                                                                                            <label className="text-order _black line-height-14" key={invoice.invoNo + index + menu.product.menu.name.th}>{menu.product.menu.name.th} x {menu.quantity}</label>
                                                                                        )
                                                                                    }
                                                                                    {invoice.statusUpdateInvoice === false ? <button className="btn btn-danger delete-invoice" onClick={() => this.deleteInvoiceProblem(invoice)}>ลบ invoice</button> : ""}
                                                                                </div>
                                                                            </label>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </label>
                                                    <div className="box-sum">
                                                        <label className="text-sum _black">ยอดรวมทั้งหมด</label>
                                                        <label className="text-sum _blue">{this.state.totalPriceInvo} บาท</label>
                                                    </div>
                                                </FormGroup>
                                                :
                                                null
                                        }

                                        {
                                            this.state.listPurchase.map((purchase, index) =>

                                                <FormGroup key={purchase.id} className="confirm">
                                                    <Input id="invoiceSelectAll"
                                                        className="radio-custom-modal"
                                                        type="radio"
                                                        name="selectInvoice"
                                                        checked={this.state.radioSelect == purchase.id ? true : false}
                                                        onChange={() => this.setState({ radioSelect: purchase.id, errMsg: false, invoSelect: [], checkInvoAll: false })}
                                                    />
                                                    <label htmlFor={purchase.id} className="radio-custom-modal-label">
                                                        <div className="wrap-topic">
                                                            <label className="topic-text">รหัสการชำระเงิน <span className="_blue _normal">{purchase.transid}</span></label>
                                                            <label className="text-status"><i className="confirm icon-circle" />แจ้งชำระแล้วรอ Admin ยืนยัน</label>
                                                        </div>
                                                        {
                                                            purchase.invoice.map((invoice, index) =>

                                                                <div className="box-order" key={invoice.invoiceNo}>
                                                                    <div className="row-order">
                                                                        <div className="topic-order">
                                                                            <label className="text-order _blue"> คำสั่งซื้อรหัส {invoice.invoiceNo} </label>
                                                                            {
                                                                                invoice.order.map(menu =>
                                                                                    <label className="text-order _black" key={invoice.invoiceNo + index + menu.product.menu.name.th}>{menu.product.menu.name.th} x {menu.quantity}</label>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <p className="text-order _blue">{invoice.priceTotal} บาท</p>
                                                                    </div>
                                                                </div>

                                                            )
                                                        }
                                                        <div className="box-sum">
                                                            <label className="text-sum _black">ยอดรวมทั้งหมด</label>
                                                            <label className="text-sum _blue">{purchase.priceTotal} บาท</label>
                                                        </div>
                                                    </label>
                                                </FormGroup>
                                            )
                                        }

                                        {
                                            this.state.listExpireInvoice.map((expire, index) =>

                                                <FormGroup key={expire.id} className="expire">

                                                    <label htmlFor={expire.id} className="radio-custom-modal-label">
                                                        <div className="wrap-topic">
                                                            <label className="topic-text">รหัสการชำระเงิน -</label>
                                                            <label className="text-status"><i className="expire icon-circle" />หมดเวลาชำระเงินแล้ว</label>
                                                        </div>

                                                        <div className="box-order" key={expire.invoNo}>
                                                            <div className="row-order">
                                                                <div className="topic-order">
                                                                    <label className="text-order _blue"> คำสั่งซื้อรหัส {expire.invoNo} </label>
                                                                    {
                                                                        expire.menu.map(menu =>
                                                                            <label className="text-order _black" key={expire.invoiceNo + index + menu.product.menu.name.th}>{menu.product.menu.name.th} x {menu.quantity}</label>
                                                                        )
                                                                    }
                                                                </div>
                                                                <p className="text-order _blue">{expire.totalPrice} บาท</p>
                                                            </div>
                                                        </div>

                                                        <div className="box-sum">
                                                            <label className="text-sum _black">ยอดรวมทั้งหมด</label>
                                                            <label className="text-sum _blue">{expire.totalPrice} บาท</label>
                                                        </div>
                                                    </label>
                                                </FormGroup>
                                            )
                                        }

                                    </div>
                                    {
                                        this.checkInvoiceError() ? <button className="btn btn-blue-white" onClick={this.onCheckInvoiceData}>เช็คเลข invoice</button> : ""
                                    }
                                    
                                    <button className="btn btn-primary-sr btn-map mt-2" onClick={this.onClickSubmit} disabled={this.state.isConfirmClick || this.state.errNoInvoice ? true : false}>อัพโหลดสลิป</button>
                                    <p className="_text-warning">{this.state.errMsg ? "กรุณาเลือก Invoice" : null}</p>
                                </div>
                            </div>

                        </ModalBody>
                }

            </Modal>
        );
    }
}



export default ModalManageInvoice


