import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { db } from '../../config/firebase'
import Switch from "react-switch";
import ModalCustomer from './ModalCustomer'
import '../../styles/chat/sideBarDetail.scss'
import ModalManageInvoice from './ModalManageInvoice'
import ModalListAddress from './ModalListAddress'
import usersCtrl from '../../controllers/usersCtrl';
import addressUsersCtrl from '../../controllers/addressUsersCtrl';
import addresssCtrl from '../../controllers/addressCtrl';
import axios from 'axios';
import api from '../../config/api';
import { Link } from 'react-router-dom';

const api_usersCtrl = new usersCtrl();
const api_addressUsersCtrl = new addressUsersCtrl();
const api_addressCtrl = new addresssCtrl();

let scrollY = 0

class sideBarDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detailUser: true,
            isShowModalCreate: false,
            isShowModalInvoice: false,
            isShowModalListAddress: false,
            userId: "",
            address: undefined,
            valueAddress: undefined,
            announceMsg: undefined,
            note: undefined,

        };
    }

    // componentWillUnmount() {
    //     this.setDefault();
    // }

    handleScroll = (e) => {
        if(document.getElementById("btn-arrow")){
            if (e.target.scrollTop < 6000 && e.target.scrollTop > scrollY) {
                document.getElementById("btn-arrow").style.display = "none"
            } else {
                document.getElementById("btn-arrow").style.display = "inline"
            }
    
            scrollY = e.target.scrollTop;
        }
    };

    setDefault = () => {
        window.removeEventListener('scroll', this.handleScroll, true)
        document.getElementById("btn-arrow").style.display = "inline"
    }

    async componentWillReceiveProps(nextProps) {

        if (this.props.id != nextProps.id & nextProps.id !== "") {

            try {
                let user
                if (this.props.dataUser[nextProps.id].platform === "mobile") {
                    const userData = await api_usersCtrl.autoUserByMobile({ displayName: this.props.dataUser[nextProps.id].name || "mockName", mobile: this.props.dataUser[nextProps.id].mobile })
                    user = userData.data
                } else {
                    user = await api_usersCtrl.autoUserByLineIdOnly({ displayName: this.props.dataUser[nextProps.id].name, lineId: nextProps.id })
                }                

                this.setState({
                    announceMsg: nextProps.dataUser[nextProps.id].announceMsg || "",
                    note: nextProps.dataUser[nextProps.id].note || "",
                    userId: user.id,
                    lineId: user.lineId,
                    address: undefined,
                    valueAddress: undefined
                })


                this.getAddress(nextProps.dataUser[nextProps.id].name, nextProps.id, nextProps.platform, nextProps.dataUser[nextProps.id].mobile)
            } catch (error) {

            }

        }
    }

    getAddress = async (name, id, platform, mobile) => {
        let user
        if (platform === "mobile" || (!platform && this.props.platform === "mobile")) {
            const userData = await api_usersCtrl.autoUserByMobile({ displayName: name || "mockName", mobile: mobile || this.props.dataUser[this.props.id].mobile })
            user = userData.data
        } else {
            user = await api_usersCtrl.autoUserByLineIdOnly({ displayName: name || "mockName", lineId: id || this.props.id })
        }
        const { data } = await api_addressUsersCtrl.getAddressUsers({
            userId: user.id
        });
        const addressDefault = data.find(item => item.no === 1);
        if (addressDefault) {
            const dataAddressUser = await api_addressCtrl.getAllAddressName({
                subdistrictName: addressDefault.subdistrict,
                districtName: addressDefault.district,
                provinceName: addressDefault.province
            })

            const listDistricts = await api_addressCtrl.getListDistricts({ provinceId: dataAddressUser.list_province.provinceId })
            const listSubDistricts = await api_addressCtrl.getListSubDistricts({ districtId: dataAddressUser.list_districts && dataAddressUser.list_districts.districtId })

            this.setState({
                address: addressDefault ? `${addressDefault.address} ${addressDefault.subdistrict} ${addressDefault.district} ${addressDefault.province} ${addressDefault.zipcode}` : undefined,
                valueAddress: addressDefault ?
                    {
                        address: addressDefault.address,
                        addressExtra: addressDefault.addressExtra,
                        location: addressDefault.location,
                        province: dataAddressUser.list_province,
                        district: dataAddressUser.list_districts,
                        subdistrict: dataAddressUser.list_subDistricts,
                        zipcode: dataAddressUser.list_subDistricts.zipcode
                    }
                    : undefined,
                listDistricts: listDistricts.data,
                listSubDistricts: listSubDistricts.data,
            })
        } else {
            this.setState({
                address: undefined,
                valueAddress: undefined,
                listDistricts: undefined,
                listSubDistricts: undefined
            })
        }

    }

    handleChangePin = () => {
        axios({
            method: "PATCH",
            url: `${api.SOCKET_API}/user`,
            data: {
                userId: this.props.id,
                pin: !this.props.pin
            }
        })
        this.props.setPin(!this.props.pin)
    }

    handleChangeAnnounce = () => {
        axios({
            method: "PATCH",
            url: `${api.SOCKET_API}/user`,
            data: {
                userId: this.props.id,
                announce: !this.props.announce
            }
        })
        this.props.setAnnounce(!this.props.announce)
    }

    onSaveAnnounce = (announceMsg) => {
        axios({
            method: "PATCH",
            url: `${api.SOCKET_API}/user`,
            data: {
                userId: this.props.id,
                announceMsg: announceMsg
            }
        })
        this.props.setAnnounceMsg(announceMsg)
    }

    onClickDetailUser = () => {
        this.setState({
            detailUser: !this.state.detailUser
        })
    }

    onSaveNote = () => {
        axios({
            method: "PATCH",
            url: `${api.SOCKET_API}/user`,
            data: {
                userId: this.props.id,
                note: this.state.note
            }
        })
    }

    showModalCreate = () => {
        this.setState({
            isShowModalCreate: true
        })
    }

    closeModalCreate = () => {
        this.setState({
            isShowModalCreate: false
        })
    }

    showModalInvoice = () => {
        this.setState({
            isShowModalInvoice: true
        })
    }

    closeModalInvoice = () => {
        this.setState({
            isShowModalInvoice: false
        })
    }

    showModalListAddress = async (edit) => {
        let user
        if (this.props.dataUser[this.props.id].platform === "mobile") {
            const userData = await api_usersCtrl.autoUserByMobile({ displayName: this.props.dataUser[this.props.id].name || "mockName", mobile: this.props.dataUser[this.props.id].mobile })
            user = userData.data
        } else {
            user = await api_usersCtrl.autoUserByLineIdOnly({ displayName: this.props.dataUser[this.props.id].name, lineId: this.props.id })
        }

        this.setState({
            isShowModalListAddress: true,
            userId: user.id,
            displayName: user.displayName,
            editAddress: edit
        })
    }

    closeModalListAddress = () => {
        this.setState({
            isShowModalListAddress: false
        })
    }

    updateDataUser = () => {
        this.props.updateDataUser(this.props.dataUser)
    }

    render() {
        return (
            <div onMouseEnter={() => window.addEventListener('scroll', this.handleScroll, true)} onMouseLeave={this.setDefault}>
                {
                    this.props.dataUser[this.props.id] && this.props.isShowChat ?
                        <div className={this.state.detailUser ? "div-side-bar-open" : "div-side-bar-close"}>
                            <button id="btn-arrow" className={this.state.detailUser ? "btn btn-open" : "btn btn-close"} onClick={this.onClickDetailUser}>
                                <i className="icon-back" />
                            </button>
                            {
                                this.state.detailUser ?
                                    <div className="wrap-side-bar">
                                        <div className="wrap-header">
                                            <div className="row-center">
                                                <div className="box-avatar">
                                                    <img src={this.props.dataUser[this.props.id].urlImg || "https://thumb.izcene.com/saranros/image/ab8fd127-0b30-43f7-b8e3-0af4aee56f56.png"} />
                                                </div>
                                            </div>
                                            <label className="name">{this.props.dataUser[this.props.id].name}</label>
                                            <div className="row-center">
                                                <Link to={"/order-history/" + this.state.userId} className="btn btn-primary-sr view-history">ดูประวัติการสั่งซื้อ</Link>
                                                <button className="btn btn-primary-sr view-history ml-2" onClick={this.showModalInvoice}>ยืนยันการโอนเงิน</button>
                                            </div>

                                            <div className="wrap-info">

                                                <div className="wrap-content">
                                                    <div className="haeder">
                                                        <label className="info-haeder">ข้อมูลลูกค้า</label>
                                                        <button className="btn" onClick={this.showModalCreate}><i className="icon-edit" /></button>
                                                    </div>
                                                    {
                                                        this.props.dataUser[this.props.id].address || this.props.dataUser[this.props.id].nickName || this.props.dataUser[this.props.id].mobile ?
                                                            <div>
                                                                <p className="info">{this.props.dataUser[this.props.id].nickName}</p>
                                                                <p className="info">{this.props.dataUser[this.props.id].mobile}</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                                <div className="wrap-content">
                                                    <div className="haeder">
                                                        <label className="info-haeder">ที่อยู่ลูกค้า</label>
                                                        <button className="btn" onClick={() => this.showModalListAddress(this.state.valueAddress ? "edit" : "add")}><i className="icon-edit" /></button>
                                                    </div>
                                                    <div className="pb-3">
                                                        {/* <p className="info">{`${this.props.dataUser[this.props.id].address} ` + `${this.props.dataUser[this.props.id].subdistrict} ` + `${this.props.dataUser[this.props.id].district} ` + `${this.props.dataUser[this.props.id].province} ` + `${this.props.dataUser[this.props.id].zipCode} `}</p> */}
                                                        <p className="info">{this.state.address}</p>
                                                        <a onClick={this.showModalListAddress} className="btn-address">เลือกที่อยู่อื่นเป็นที่อยู่หลัก</a>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>

                                        <div className="wrap-setting">
                                            <div className="row-setting">
                                                <label className="text-setting">ปักหมุดแชท</label>
                                                <label htmlFor="small-radius-switch-pin" className="mb-0">
                                                    <Switch
                                                        checked={this.props.pin}
                                                        onChange={this.handleChangePin}
                                                        handleDiameter={18}
                                                        offColor="#828282"
                                                        onColor="#004EC2"
                                                        offHandleColor="#ffffff"
                                                        onHandleColor="#ffffff"
                                                        height={22}
                                                        width={42}
                                                        className="react-switch"
                                                        id="small-radius-switch-pin"
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="wrap-setting">
                                            <div className="row-setting">
                                                <label className="text-setting">ประกาศข้อความ</label>
                                                <label htmlFor="small-radius-switch-annouce" className="mb-0">
                                                    <Switch
                                                        checked={this.props.announce}
                                                        onChange={this.handleChangeAnnounce}
                                                        handleDiameter={18}
                                                        offColor="#828282"
                                                        onColor="#004EC2"
                                                        offHandleColor="#ffffff"
                                                        onHandleColor="#ffffff"
                                                        height={22}
                                                        width={42}
                                                        className="react-switch"
                                                        id="small-radius-switch-annouce"
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                    />
                                                </label>
                                            </div>
                                            <FormGroup>
                                                <Input type="textarea" onChange={(e) => this.setState({ announceMsg: e.target.value })} onBlur={(e) => this.onSaveAnnounce(e.target.value)} value={this.state.announceMsg} />
                                            </FormGroup>
                                        </div>


                                        <div className="wrap-setting">
                                            <div className="row-setting">
                                                <label className="text-setting">เพิ่มโน้ต</label>
                                                <button className="btn-sm btn-primary-sr" onClick={this.onSaveNote}>บันทึก</button>
                                            </div>
                                            <FormGroup>
                                                <Input type="textarea" onChange={(e) => this.setState({ note: e.target.value })} value={this.state.note} />
                                            </FormGroup>
                                        </div>
                                        <div className="cover-bar" />
                                    </div>
                                    :
                                    null
                            }


                        </div>
                        :
                        null
                }
                <ModalCustomer
                    showModalCreate={this.state.isShowModalCreate}
                    closeModalCreate={this.closeModalCreate}
                    title="ข้อมูลลูกค้า"
                    id={this.props.id}
                    dataUser={this.props.dataUser[this.props.id]}
                    updateDataUser={this.updateDataUser}
                />

                <ModalManageInvoice
                    showModalInvoice={this.state.isShowModalInvoice}
                    closeModalInvoice={this.closeModalInvoice}
                    id={this.props.platform == 'mobile' ? this.props.dataUser[this.props.id].mobile : this.props.id}
                    name={this.props.dataUser[this.props.id] ? this.props.dataUser[this.props.id].name : ""}
                    platform={this.props.dataUser[this.props.id] ? this.props.dataUser[this.props.id].platform : ""}
                />

                <ModalListAddress
                    isOpen={this.state.isShowModalListAddress}
                    closeModal={this.closeModalListAddress}
                    userId={this.state.userId}
                    userLineId={this.state.lineId}
                    editAddress={this.state.editAddress}
                    valueAddress={this.state.valueAddress}
                    listDistricts={this.state.listDistricts}
                    listSubDistricts={this.state.listSubDistricts}
                    refresh={this.getAddress}
                    platform={this.props.platform}
                />
            </div>
        )
    }
}

export default sideBarDetail