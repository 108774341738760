import axios_saranros from '../lib/axios-saranros';
import * as utils from '../utils/utils';


class OrderCtrl {


    createOrder = (params) => {
        return new Promise(async (resolve, reject) => {
            // return new Promise(async (resolve, reject) => {
            try {

                // console.log("params", params);

                const url = 'api/Orders/v2/createOrder';
                const result = await axios_saranros.post(url, { ...params })
                resolve(result)
            } catch (error) {
                reject(error);
            }
        })
    }



    createShippingCosts = async (params) => {
        return new Promise(async (resovle, reject) => {
            try {
                const { order, orderSuccess, stateCreateOrder } = params;
                const this_orderShopDefault = stateCreateOrder.orderShop[0];

                const data = {
                    shopId: order.isPromotion99 ? order.mainData.hubShopId : order.mainData.shopId,
                    userId: stateCreateOrder.userCustomer.id,
                    methodPayment: 'transfer',
                    deliveryBy: order.deliveryOption,
                    deliveryNote: order.checkShippingCosts.deliveryNote,
                    deliveryAt: [{
                        location: order.isDefaultContactCustomer ? [this_orderShopDefault.checkShippingCosts.deliveryAt[0].location[0].toString(), this_orderShopDefault.checkShippingCosts.deliveryAt[0].location[1].toString()]
                            : [order.checkShippingCosts.deliveryAt[0].location[0].toString(), order.checkShippingCosts.deliveryAt[0].location[1].toString()],
                        address: order.isDefaultContactCustomer ? this_orderShopDefault.checkShippingCosts.deliveryAt[0].address : order.checkShippingCosts.deliveryAt[0].address,
                        addressExtra: order.isDefaultContactCustomer ? this_orderShopDefault.checkShippingCosts.deliveryAt[0].addressExtra : order.checkShippingCosts.deliveryAt[0].addressExtra,
                        // name: order.isDefaultContactCustomer ? this_orderShopDefault.createUser.name : order.createUser.name,
                        // mobile: order.isDefaultContactCustomer ? this_orderShopDefault.createUser.mobile : order.createUser.mobile,
                        name: order.createUser.name,
                        mobile: order.createUser.mobile,
                        typeCar: "MOTORCYCLE",
                        dateDelivery: order.checkShippingCosts.deliveryAt[0].dateDelivery,
                        // dateDelivery: this_orderShopDefault.checkShippingCosts.deliveryAt[0].dateDelivery,
                        productType: "preorder",
                        invoiceId: orderSuccess[0].invoiceId,
                        isBox: ((order.deliveryOption === "LLM" || order.deliveryOption === "SKOOTAR") ? order.isBox : false),
                        isIndividually: ((order.deliveryOption === "LLM" || order.deliveryOption === "SKOOTAR") ? order.isIndividually : false),
                        addressId: order.isDefaultContactCustomer ? this_orderShopDefault.checkShippingCosts.deliveryAt[0].addressId : order.checkShippingCosts.deliveryAt[0].addressId,
                        subdistrict: order.isDefaultContactCustomer ? this_orderShopDefault.checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName : order.checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName,
                        district: order.isDefaultContactCustomer ? this_orderShopDefault.checkShippingCosts.deliveryAt[0].district.districtName : order.checkShippingCosts.deliveryAt[0].district.districtName,
                        province: order.isDefaultContactCustomer ? this_orderShopDefault.checkShippingCosts.deliveryAt[0].province.provinceName : order.checkShippingCosts.deliveryAt[0].province.provinceName,
                        zipcode: order.isDefaultContactCustomer ? this_orderShopDefault.checkShippingCosts.deliveryAt[0].zipcode : order.checkShippingCosts.deliveryAt[0].zipcode,
                        priceShippingNet: order.shippingCost.deliveryPrice
                    }]
                };

                if(data.deliveryBy != "SPECIAL") {
                    delete data.deliveryNote
                }
                const url = 'api/Invoices/v2/shippingCosts';
                const result = await axios_saranros.post(url, { ...data });
                resovle(result);
            } catch (error) {
                reject(error);
            }
        });
    }



    updateInvoiceById = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { order, orderSuccess } = params;
                const invoiceId = orderSuccess[0].invoiceId;
                const specialDisCount = order.updateInvoiceById.priceSpecialDiscount;
                const data = {
                    message: order.updateInvoiceById.message,
                    priceSpecialDiscount: {
                        shippingDiscount: (specialDisCount && specialDisCount.shippingDiscount.toString().trim().length !== 0 ? parseFloat(specialDisCount.shippingDiscount) : 0) || 0,
                        foodDiscount: (specialDisCount && specialDisCount.foodDiscount.toString().trim().length !== 0 ? parseFloat(specialDisCount.foodDiscount) : 0) || 0,
                        note: (specialDisCount && specialDisCount.note) || '',
                        code: (specialDisCount && specialDisCount.encode) || ''
                    }
                }


                if (specialDisCount == null) {
                    delete data.priceSpecialDiscount;
                }
                const url = 'api/Invoices/' + invoiceId
                const result = await axios_saranros.patch(url, data);
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }



    updateInvoiceByUserId = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { stateCreateOrder } = params;
                const userId = stateCreateOrder.userCustomer.id;
                const data = {
                    status: "confirm"
                }

                const url = "api/Invoices/v2/user/" + userId;
                const result = await axios_saranros.patch(url, data);
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }



    updateOrderQuatlity = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "api/Orders/v2/updateOrderQuatlity";
                const result = await axios_saranros.patch(url, { ...params });
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }

    managecreateOrder = async (order, stateCreateOrder) => {
        return new Promise(async resolve => {

            const newOrder = [];
            const listMenuSelect = order.listMenuSelect;
            const this_orderShopDefault = stateCreateOrder.orderShop[0];
            let statusCreateOrder = true;
            const orderSuccess = [];
            const orderError = [];
            const awaitNewResult = await Object.keys(listMenuSelect).map(async (key, index) => {
                newOrder.push(listMenuSelect[key]);
            });

            let objectOrder = {};
            const dateDelivery = order.checkShippingCosts.deliveryAt[0].dateDelivery;
            for (let i in newOrder) {
                // console.log("newOrder", newOrder[i]);
                const this_order = newOrder[i];
                if (!this_order.error.statusCreate) {
                    try {
                        const data = {
                            priceTotal: this_order.quantity * this_order.product.dataProduct[0].priceNet,
                            quantity: this_order.quantity,
                            // shopId: order.isPromotion99 ? order.mainData.hubShopId : order.mainData.shopId,
                            shopId: order.mainData.shopId,
                            // shopId: this_order.product._id != "5df31239b744ad383d664f94" ? order.mainData.shopId : "5d43f03b46979a31e90ad4ddasdadasasda",
                            userId: stateCreateOrder.userCustomer.id,
                            productId: this_order.product.dataProduct[0]._id,
                            typeOrder: 'preorder',
                            channel: 'cms',
                            contactId: order.isPromotion99 ? order.hubContactShop._id : order.contactShop._id,
                            deliveryOption: order.deliveryOption,
                            dateDelivery: dateDelivery,
                        }


                        const res_createOrder = await this.createOrder(data);
                        orderSuccess.push(res_createOrder.data);

                        objectOrder = {
                            ...objectOrder,
                            [this_order.product.dataProduct[0]._id]: {
                                ...this_order,
                                error: {
                                    statusCreate: true,
                                    messageError: ''
                                },
                                orderSuccess: res_createOrder.data
                            }
                        }

                        // console.log(i, data);
                    } catch (error) {
                        if (error.response.data.error.statusCode === 422) {
                            statusCreateOrder = false;
                            objectOrder = {
                                ...objectOrder,
                                [this_order.product.dataProduct[0]._id]: {
                                    ...this_order,
                                    error: {
                                        statusCreate: false,
                                        messageError: error.response.data.error.name
                                    },
                                }
                            }
                            orderError.push({
                                ...this_order,
                                error: {
                                    statusCreate: false,
                                    messageError: error.response.data.error.name
                                }
                            });
                        }
                    }
                } else {
                    orderSuccess.push(this_order.orderSuccess);
                    objectOrder = {
                        ...objectOrder,
                        [this_order.product.dataProduct[0]._id]: {
                            ...this_order
                        }
                    }
                }
            }
            resolve({
                statusCreateOrder,
                objectOrder,
                orderSuccess,
                orderError: orderError
            });

        })
    };


    deleteOrdertMulti = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "api/Orders/v2/deleteOrdertMulti";
                const orderDelete = {
                    order: [params]
                };
                const result = await axios_saranros.delete(url, { data: orderDelete });
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }

    calculatePriceDiscountDrlivery = (item) => {
        const { updateInvoiceById, errors } = item;
        if (updateInvoiceById.priceSpecialDiscount && !errors.specialDiscount.code) {
            return parseFloat(updateInvoiceById.priceSpecialDiscount.shippingDiscount) || 0;
        }
        return 0
    }

    calculatePriceDiscountFood = (item) => {
        const { updateInvoiceById, errors } = item;
        if (updateInvoiceById.priceSpecialDiscount && !errors.specialDiscount.code) {
            return parseFloat(updateInvoiceById.priceSpecialDiscount.foodDiscount) || 0;
        }
        return 0
    }


    createForMatMessage = (orderShop, userChat, token_payment, platform) => {
        const messageSplit = []
        let message = "รบกวนตรวจสอบ Order ก่อนชำระค่าบริการ\n--------------------------------------------\n";
        messageSplit.push(message)
        let deliveryPrice = 0;
        let sumFood = 0;
        let discountDeliveryPrice = 0;
        let discountSumFood = 0;
        const type_ALL = orderShop.filter((item) => item.deliveryOption !== "SPECIAL");
        const type_SKOOTAR = orderShop.filter((item) => item.deliveryOption === "SKOOTAR" || item.deliveryOption === "LLM");
        const type_CPIU = orderShop.filter((item) => item.deliveryOption === "CPIU");
        const type_KERRY = orderShop.filter((item) => item.deliveryOption === "KERRY");
        const type_SPECIAL = orderShop.filter((item) => item.deliveryOption === "SPECIAL");
        const type_SARANROS_FIXED = orderShop.filter((item) => item.deliveryOption === "SARANROSFIXED");

        for (let i in orderShop) {
            // deliveryPrice += (orderShop[i].deliveryOption === "SKOOTAR" || orderShop[i].deliveryOption === "LLM" || orderShop[i].deliveryOption === "KERRY") ? orderShop[i].shippingCost.deliveryPrice : 0;
            deliveryPrice += orderShop[i].shippingCost.deliveryPrice || 0;
            const sumPriceFood = this.sumPriceNet(orderShop[i].listMenuSelect);
            sumFood += sumPriceFood;

            discountDeliveryPrice += this.calculatePriceDiscountDrlivery(orderShop[i]);
            discountSumFood += this.calculatePriceDiscountFood(orderShop[i]);

            const messageOrder = this.checkShowTypeOrder(orderShop[i], i, orderShop);
            message += messageOrder;
            messageSplit.push(messageOrder)
            if (orderShop.length !== 1) {
                message += "--------------------------------------------\n";
                // messageSplit.push("--------------------------------------------\n");
            }

        }

        if (orderShop.length === 1) {
            message += "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((deliveryPrice + sumFood) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
            messageSplit.push("ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((deliveryPrice + sumFood) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
        } else {
            if (type_ALL.length !== 0 && type_SPECIAL.length === 0) { // type SKOOTAR all.
                message += "● สรุป\n" +
                    "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
                    "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
                    "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
                messageSplit.push("● สรุป\n" +
                    "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
                    "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
                    "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
            } else if (type_SPECIAL.length !== 0 && type_ALL.length === 0) { // type CPIU all.
                message += "● สรุป\n" +
                    "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
                    "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
                    "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
                messageSplit.push("● สรุป\n" +
                    "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
                    "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
                    "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
            } else {
                message += "● สรุป\n" +
                    "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
                messageSplit.push("● สรุป\n" +
                    "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
            }
        }

        // {
        //     if (type_SKOOTAR.length !== 0 && type_CPIU.length === 0 && type_KERRY.length === 0) { // type SKOOTAR all.
        //         message += "● สรุป\n" +
        //             "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
        //             "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
        //         messageSplit.push("● สรุป\n" +
        //             "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
        //             "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
        //     } else if (type_CPIU.length !== 0 && type_SKOOTAR.length === 0 && type_KERRY.length === 0) { // type CPIU all.
        //         message += "● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
        //         messageSplit.push("● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
        //     } else if (type_KERRY.length !== 0 && type_SKOOTAR.length === 0 && type_CPIU.length === 0) { // type CPIU all.
        //         message += "● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
        //         messageSplit.push("● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
        //     } else if (type_SPECIAL.length !== 0 && type_SKOOTAR.length === 0 && type_CPIU.length === 0) {
        //         message += "● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
        //         messageSplit.push("● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
        //     } else if (type_ALL.length !== 0 && type_SKOOTAR.length === 0 && type_CPIU.length === 0) {
        //         message += "● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
        //         messageSplit.push("● สรุป\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
        //     } else { // all type
        //         message += "● สรุป\n" +
        //             "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
        //             "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n";
        //         messageSplit.push("● สรุป\n" +
        //             "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(sumFood - discountSumFood) + " บาท\n" +
        //             "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(deliveryPrice - discountDeliveryPrice) + " บาท\n\n" +
        //             "ยอดโอนชำระทั้งหมด " + (utils.numberFormatDecimalTwoPoint((sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice))) + " บาท\n\n")
        //     }
        // }

        message += "ชำระเงินโดยการโอนเงินมาที่\n" +
            "ธนาคารกสิกรไทย\n" +
            "ชื่อบัญชี บจ.กระยาหารสราญรส\n" +
            "บัญชีเงินฝากออมทรัพย์ สาขา เดอะไนน์ เซ็นเตอร์\n" +
            "เลขบัญชี\n" +
            "6651013222\n" +
            "กรุณาชำระเงิน และแนบหลักฐานการชำระเงินผ่านช่องทาง\n" +
            "Line หรือ Facebook Messenger\n\n" +
            "หรือ\n\n"
        message += "คลิกที่ลิงก์ด้านล่างนี้เพื่อชำระเงิน\n"
        messageSplit.push("ชำระเงินโดยการโอนเงินมาที่\n" +
            "ธนาคารกสิกรไทย\n" +
            "ชื่อบัญชี บจ.กระยาหารสราญรส\n" +
            "บัญชีเงินฝากออมทรัพย์ สาขา เดอะไนน์ เซ็นเตอร์\n" +
            "เลขบัญชี\n" +
            "6651013222\n" +
            "กรุณาชำระเงิน และแนบหลักฐานการชำระเงินผ่านช่องทาง\n" +
            "Line หรือ Facebook Messenger\n\n" +
            "หรือ\n\n"
            +
            "คลิกที่ลิงก์ด้านล่างนี้เพื่อชำระเงิน\n")

        if (process.env.NODE_ENV === "development") {
            if (platform == "line") {
                // message += "line://app/1610412023-21zDQ38v" + "\n";
            } else {
                message += "https://demo.vingtv.com/paymentNoAuth/" + token_payment + "\n";
                messageSplit.push("https://demo.vingtv.com/paymentNoAuth/" + token_payment + "\n")
            }
        } else {
            if (platform == "line") {
                // message += "line://app/1653770232-kzzEzpjp" + "\n";
            } else {
                message += "https://market.saranros.com/paymentNoAuth/" + token_payment + "\n";
                messageSplit.push("https://market.saranros.com/paymentNoAuth/" + token_payment + "\n")
            }
        }

        const messageType = {
            message,
            messageSplit
        }


        return messageType;
    }

    checkShowTypeOrder = (item, idx, orderShop) => {
        if (item.deliveryOption === "SKOOTAR" || item.deliveryOption === "LLM") {
            return this.showOrderLogisticAll(item, idx, orderShop)
        } else if (item.deliveryOption === "CPIU") {
            return this.showOrderCPIU(item, idx, orderShop)
        } else if (item.deliveryOption === "KERRY") {
            return this.showOrderLogisticAll(item, idx, orderShop)
        } else if (item.deliveryOption === "SPECIAL") {
            return this.showOrderSpecial(item, idx, orderShop)
        } else if (item.deliveryOption === "SARANROSFIXED"){
            return this.showOrderSaranrosFixed(item, idx, orderShop)
        } else {
            return this.showOrderLogisticAll(item, idx, orderShop)
        }
    }

    showOrderSaranrosFixed = (item, idx, orderShop) => {
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        const specialTypeDelivery = item.checkShippingCosts
        const index = parseInt(idx);
        const logisticLabel = orderShop[idx] && orderShop[idx].listLogistic.filter((code) => item.deliveryOption == code.logistic.code)

        let messageTypeSaranrosFixed = "● การจัดส่งที่ " + (index + 1) + "\n \n" +
            "สินค้าจาก " + item.mainData.dataShop[0].name + ":\n"

        Object.keys(listMenuSelect).map((key, index) => {
            const this_order = listMenuSelect[key];
            messageTypeSaranrosFixed += "-" + (this_order.product.name.th || this_order.product.name.en) + " x " + this_order.quantity + " ราคา " + utils.numberFormatDecimalTwoPoint(this_order.quantity * this_order.product.dataProduct[0].priceNet) + " บาท\n"

        });

        messageTypeSaranrosFixed += "\nจัดส่ง\n"
            + "คุณ " + (item.createUser.name) + "\n" +
            "เบอร์ " + (utils.formatPhoneNumber(item.createUser.mobile)) + "\n"
            + (
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            )
            + "\n"
            + (
                `${deliveryAtItem.addressExtra}`
            )
            + "\n"
            + "จัดส่งโดย " + logisticLabel[0].logistic.label + "\n"
            + "รอบ " + utils.changeFormatDateFull(deliveryAtItem.dateDelivery) + "  ไม่เกิน 16.30 น.\n\n";


        if (orderShop.length === 1) {
            messageTypeSaranrosFixed += "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item)) + " บาท\n\n";
        } else {
            messageTypeSaranrosFixed += "ค่าอาหาร " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่ง " + (utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item))) + " บาท\n" +
                "ยอดรวม " + (utils.numberFormatDecimalTwoPoint((item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect) - (this.calculatePriceDiscountDrlivery(item) + this.calculatePriceDiscountFood(item))))) + " บาท\n"
        }
        return messageTypeSaranrosFixed;
    };


    showOrderSKOOTAR = (item, idx, orderShop) => {
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        const index = parseInt(idx);
        let messageTypeSKOOTAR = "● การจัดส่งที่ " + (index + 1) + "\n \n" +
            "สินค้าจาก " + item.mainData.dataShop[0].name + ":\n"
        Object.keys(listMenuSelect).map((key, index) => {
            const this_order = listMenuSelect[key];
            messageTypeSKOOTAR += "-" + (this_order.product.name.th || this_order.product.name.en) + " x " + this_order.quantity + " ราคา " + utils.numberFormatDecimalTwoPoint(this_order.quantity * this_order.product.dataProduct[0].priceNet) + " บาท\n"

        });

        messageTypeSKOOTAR += "\nจัดส่ง\n"
            + "คุณ " + (item.createUser.name) + "\n" +
            "เบอร์ " + (utils.formatPhoneNumber(item.createUser.mobile)) + "\n"
            + (
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            )
            + "\n"
            + (
                `${deliveryAtItem.addressExtra}`
            )
            + "\n"
            + "จัดส่งโดย แมสเซ็นเจอร์\n"
            + "รอบ " + utils.changeFormatDateFull(deliveryAtItem.dateDelivery) + " " + utils.changeFormatTime(deliveryAtItem.dateDelivery) + "  น.+++ โดยประมาณ\n\n";


        if (orderShop.length === 1) {
            messageTypeSKOOTAR += "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item)) + " บาท\n\n";

        } else {
            messageTypeSKOOTAR += "ค่าอาหาร " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่ง " + (utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item))) + " บาท\n" +
                "ยอดรวม " + (utils.numberFormatDecimalTwoPoint((item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect) - (this.calculatePriceDiscountDrlivery(item) + this.calculatePriceDiscountFood(item))))) + " บาท\n"
        }

        return messageTypeSKOOTAR;
    };

    showOrderKERRY = (item, idx, orderShop) => {
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        const index = parseInt(idx);
        let messageTypeKERRY = "● การจัดส่งที่ " + (index + 1) + "\n \n" +
            "สินค้าจาก " + item.mainData.dataShop[0].name + ":\n"

        Object.keys(listMenuSelect).map((key, index) => {
            const this_order = listMenuSelect[key];
            messageTypeKERRY += "-" + (this_order.product.name.th || this_order.product.name.en) + " x " + this_order.quantity + " ราคา " + utils.numberFormatDecimalTwoPoint(this_order.quantity * this_order.product.dataProduct[0].priceNet) + " บาท\n"

        });

        messageTypeKERRY += "\nจัดส่ง\n"
            + "คุณ " + (item.createUser.name) + "\n" +
            "เบอร์ " + (utils.formatPhoneNumber(item.createUser.mobile)) + "\n"
            + (
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            )
            + "\n"
            + (
                `${deliveryAtItem.addressExtra}`
            )
            + "\n"
            + "จัดส่งโดย Kerry\n"
            + "รอบ " + utils.changeFormatDateFull(deliveryAtItem.dateDelivery) + " " + utils.changeFormatTime(deliveryAtItem.dateDelivery) + "  น.+++ โดยประมาณ\n\n";


        if (orderShop.length === 1) {
            messageTypeKERRY += "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item)) + " บาท\n\n";

        } else {
            messageTypeKERRY += "ค่าอาหาร " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่ง " + (utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item))) + " บาท\n" +
                "ยอดรวม " + (utils.numberFormatDecimalTwoPoint((item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect)) - (this.calculatePriceDiscountDrlivery(item) + this.calculatePriceDiscountFood(item)))) + " บาท\n"
        }

        return messageTypeKERRY;
    };


    showOrderCPIU = (item, idx, orderShop) => {

        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        let messageTypeCPIU = "● การจัดส่งที่ " + (parseInt(idx) + 1) + "\n \n" +
            "สินค้าจาก " + item.mainData.dataShop[0].name + ":\n"

        Object.keys(listMenuSelect).map((key, index) => {
            const this_order = listMenuSelect[key];
            messageTypeCPIU += "-" + (this_order.product.name.th || this_order.product.name.en) + " x " + this_order.quantity + " ราคา " + utils.numberFormatDecimalTwoPoint(this_order.quantity * this_order.product.dataProduct[0].priceNet) + " บาท\n"

        });

        messageTypeCPIU += "\nจัดส่ง\n"
            + "คุณ " + (item.createUser.name) + "\n" +
            "เบอร์ " + (utils.formatPhoneNumber(item.createUser.mobile)) + "\n"
            + "สถานที่นัดรับ " +
            (
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            )
            + "\n"
            + "วันเวลานัดรับ " + utils.changeFormatDateFull(deliveryAtItem.dateDelivery) + " " + utils.changeFormatTime(deliveryAtItem.dateDelivery) + "  น.\n\n";


        if (orderShop.length !== 1) {
            messageTypeCPIU += "ค่าอาหาร " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n";
        }
        return messageTypeCPIU;

    };

    showOrderSpecial = (item, idx, orderShop) => {
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        const specialTypeDelivery = item.checkShippingCosts
        const index = parseInt(idx);
        const deliveryNote = specialTypeDelivery.deliveryNote ? `(${specialTypeDelivery.deliveryNote})` : ""
        // const listLogistic = store.getState().logistics.listLogistic
        // const logisticSelect = listLogistic.filter((code) => item.deliveryOption == code.logistic.code)

        let messageTypeSpecial = "● การจัดส่งที่ " + (index + 1) + "\n \n" +
            "สินค้าจาก " + item.mainData.dataShop[0].name + ":\n"

        Object.keys(listMenuSelect).map((key, index) => {
            const this_order = listMenuSelect[key];
            messageTypeSpecial += "-" + (this_order.product.name.th || this_order.product.name.en) + " x " + this_order.quantity + " ราคา " + utils.numberFormatDecimalTwoPoint(this_order.quantity * this_order.product.dataProduct[0].priceNet) + " บาท\n"
        });

        messageTypeSpecial += "\nจัดส่ง\n"
            + "คุณ " + (item.createUser.name) + "\n" +
            "เบอร์ " + (utils.formatPhoneNumber(item.createUser.mobile)) + "\n"
            + (
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            )
            + "\n"
            + (
                `${deliveryAtItem.addressExtra}`
            )
            + "\n" + "จัดส่งโดย ขนส่งพิเศษ " + deliveryNote
            + "\n"
            + "รอบ " + utils.changeFormatDateFull(deliveryAtItem.dateDelivery) + " " + utils.changeFormatTime(deliveryAtItem.dateDelivery) + `${item.checkShippingCosts.deliveryNote == "promotion99" ? "-18.00 น. โดยประมาณ\n\n" : "น.+++ โดยประมาณ\n\n"}`;


        if (orderShop.length === 1) {
            messageTypeSpecial += "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item)) + " บาท\n\n";

        } else {
            messageTypeSpecial += "ค่าอาหาร " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่ง " + (utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item))) + " บาท\n" +
                "ยอดรวม " + (utils.numberFormatDecimalTwoPoint((item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect) - (this.calculatePriceDiscountDrlivery(item) + this.calculatePriceDiscountFood(item))))) + " บาท\n"
        }

        return messageTypeSpecial;
    };

    showOrderLogisticAll = (item, idx, orderShop) => {
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        const specialTypeDelivery = item.checkShippingCosts
        const index = parseInt(idx);
        const logisticLabel = orderShop[idx] && orderShop[idx].listLogistic.filter((code) => item.deliveryOption == code.logistic.code)

        let messageTypeSKOOTAR = "● การจัดส่งที่ " + (index + 1) + "\n \n" +
            "สินค้าจาก " + item.mainData.dataShop[0].name + ":\n"

        Object.keys(listMenuSelect).map((key, index) => {
            const this_order = listMenuSelect[key];
            messageTypeSKOOTAR += "-" + (this_order.product.name.th || this_order.product.name.en) + " x " + this_order.quantity + " ราคา " + utils.numberFormatDecimalTwoPoint(this_order.quantity * this_order.product.dataProduct[0].priceNet) + " บาท\n"

        });

        messageTypeSKOOTAR += "\nจัดส่ง\n"
            + "คุณ " + (item.createUser.name) + "\n" +
            "เบอร์ " + (utils.formatPhoneNumber(item.createUser.mobile)) + "\n"
            + (
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            )
            + "\n"
            + (
                `${deliveryAtItem.addressExtra}`
            )
            + "\n"
            + "จัดส่งโดย " + logisticLabel[0].logistic.label + "\n"
            + "รอบ " + utils.changeFormatDateFull(deliveryAtItem.dateDelivery) + " " + utils.changeFormatTime(deliveryAtItem.dateDelivery) + "  น.+++ โดยประมาณ\n\n";


        if (orderShop.length === 1) {
            messageTypeSKOOTAR += "ค่าอาหารทั้งหมด " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่งทั้งหมด " + utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item)) + " บาท\n\n";
        } else {
            messageTypeSKOOTAR += "ค่าอาหาร " + utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item)) + " บาท\n" +
                "ค่าจัดส่ง " + (utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item))) + " บาท\n" +
                "ยอดรวม " + (utils.numberFormatDecimalTwoPoint((item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect) - (this.calculatePriceDiscountDrlivery(item) + this.calculatePriceDiscountFood(item))))) + " บาท\n"
        }
        return messageTypeSKOOTAR;
    };


    sumPriceNet = (listMenuSelects) => {
        const total = Object.keys(listMenuSelects).reduce(function (previous, key) { // for loop sum total price
            const result = parseFloat(previous + (listMenuSelects[key].quantity * listMenuSelects[key].product.dataProduct[0].priceNet));
            if (isNaN(result)) {
                return 0
            }
            return result
        }, 0);
        return total;
    }

}




export default OrderCtrl;