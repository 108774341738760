import api from '../config/api'
import axios from 'axios'


const genCode = async (id) => {

    const code = await axios({
        method: 'GET',
        url: `${api.SOCKET_API}/genCodeById/${id}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return code.data.code
}

const getUserFromCode = async (code) => {

    const user = await axios({
        method: 'GET',
        url: `${api.SOCKET_API}/getUserByCode/${code}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return user.data

}

const sendDataToLine = async (data) => {

    await axios({
        method: 'POST',
        url: `${api.CONNECTOR_SOCIAL_API}/pushMessageToLine`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })
}

const sendDataToLine_Promise = async (params) => {

    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios({
                method: 'POST',
                url: `${api.CONNECTOR_SOCIAL_API}/pushMessageToLine`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: params
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    })
}


const pushBtnPaymentToLine = async (params) => {

    return new Promise(async (resolve, reject) => {
        const { lineId } = params;
        try {
            const result = await axios({
                method: 'POST',
                url: `${api.CONNECTOR_SOCIAL_API}/pushBtnPaymentToLine`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    lineId: lineId
                }
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    })
}

const getTagColors = async () => {
    const color = await axios({
        method: 'GET',
        url: `${api.SOCKET_API}/colorTags`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return color.data
}

const getTags = async () => {
    const tags = await axios({
        method: 'GET',
        url: `${api.SOCKET_API}/Tags`,
        headers: {
            'Content-Type': 'application/json',
            'secret': "b87d6b34-1e15-4ae7-9bb6-cec268803a65",
        }
    })

    return tags.data
}

const getTagsById = async (id) => {
    const tags = await axios({
        method: 'GET',
        url: `${api.SOCKET_API}/Tags/${id}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return tags.data
}

const createTags = async (data) => {
    await axios({
        method: 'POST',
        url: `${api.SOCKET_API}/createTag`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })
}

const addTags = async (data) => {
    await axios({
        method: 'POST',
        url: `${api.SOCKET_API}/addTag`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })
}

const updateTimeUploadImage = async (data) => {
    await axios({
        method: 'PATCH',
        url: `${api.SOCKET_API}/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })
}


export {
    genCode,
    getUserFromCode,
    sendDataToLine,
    sendDataToLine_Promise,
    getTagColors,
    getTags,
    getTagsById,
    createTags,
    addTags,
    pushBtnPaymentToLine,
    updateTimeUploadImage,
}