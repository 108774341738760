
import axios_saranros from '../lib/axios-saranros';
import * as utils from "../utils/utils";
import api from '../config/api';


class shopCtrl {
    getShoplist = async () => {

        return new Promise(async (resolve, reject) => {
            try {
                const url = `${api.SARANROS_API}/api/Shops`
                const result = await axios_saranros.get(url)
                resolve(result)
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }

    getNameShopByShopId = async (shopId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const filter = {
                    where : {
                        id: shopId
                    }
                }
                const url = `/api/Shops?filter=${JSON.stringify(filter)}`
                const result = await axios_saranros.get(url)
                resolve(result)
            } catch(err) {
                console.log(err)
                reject(err)
            }
        })
    }
}

export default shopCtrl
