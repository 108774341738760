import React, { Component } from 'react'
import { db, timeServer } from '../../config/firebase';
import '../../styles/chat/chat.scss'
import axios from 'axios'
import ScrollToBottom from 'react-scroll-to-bottom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, Input, FormGroup, Label } from 'reactstrap';
import { isEmptyOrSpaces, convertTimeStampToFormant } from '../../utils/utils'
import api from '../../config/api'
import GoogleMap from './gooogleMap'
import ModalPreview from './ModalPreview'
import ModalManageInvoice from './ModalManageInvoice'
import ModalTag from './ModalTag'
import '../../styles/chat/sideBarDetail.scss'
import { genCode, sendDataToLine, getTagsById } from '../../controllers/chatCtrl'
import saveAs from 'file-saver'
import Cookies from 'js-cookie'
import socketIOClient from 'socket.io-client'
import ArrowLrft from '../../assets/images/arrow-left.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ModalSelectMedia from '../gallery/ModalSelectMedia';
import { createUploadMediaLog } from '../../controllers/mediaGalleryCtrl'
import imgcloud from '../../assets/images/img-cloud.png'


const pathImage = "https://thumb.izcene.com/saranros/"
// const pathImage = "https://secure-media.saranros.com/image/"
const pathVideo = "https://secure-media.saranros.com/video/vdo/"

class ListChat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roomId: "",
            endpoint: api.SOCKET_API,
            listChat: [],
            msgData: "",
            tagName: "",
            toggle: false,
            listTags: [],
            nameTagCreate: "",
            tagSelectShow: [],
            typeInput: "text",
            file: "",
            imgPreviewUrl: "",
            listColorTags: [],
            colorTagCreate: undefined,
            isShowModalTag: false,
            isShowModalPreview: false,
            isShowModalInvoice: false,
            isShowModalSelectGallery: false,
        }

        this.connSocket = undefined
        this.limPage = 10
        this.fetchMore = true
        this.scrollChat = 0
    }

    async componentWillReceiveProps(nextProps) {
        if (this.props.id != nextProps.id & nextProps.id !== "") {
            this.limPage = 10
            this.list(nextProps.id)
            this.getTagShow(nextProps.id)
        }

        if (!nextProps.isShowChat && this.props.id != nextProps.id) {
            if (this.connSocket) {
                this.connSocket.disconnect()
            }
        }
    }

    showModalPreview = (img) => {
        this.setState({
            isShowModalPreview: true,
            imagePreview: img
        })
    }

    closeModalPreview = () => {
        this.setState({
            isShowModalPreview: false
        })
    }

    showModalInvoice = (slip, idMsg) => {
        this.setState({
            isShowModalInvoice: true,
            slip: slip,
            idMsg: idMsg
        })
    }

    closeModalInvoice = () => {
        this.setState({
            isShowModalInvoice: false
        })
    }

    showModalTag = () => {
        this.setState({
            isShowModalTag: true
        })
    }

    closeModalTag = () => {
        this.setState({
            isShowModalTag: false
        })
    }

    getTagShow = async (id) => {
        const userTagData = await getTagsById(id)

        let mockTagSelectShow = []

        userTagData.map((doc, index) => {
            mockTagSelectShow[index] = doc.tagId
        })

        this.setState({
            tagSelectShow: mockTagSelectShow,
        })
    }

    list = async (userId) => {

        if (this.connSocket) {
            this.connSocket.disconnect()
        }

        this.setState({
            roomId: userId
        })

        const historyMsg = await this.getMessageUser(userId, this.limPage)

        this.setState({ listChat: historyMsg })
        if (historyMsg.length > 0 && this.limPage == 10) {
            const lastTagMsg = document.getElementsByClassName('wd-he-toscroll')
            if (lastTagMsg) {
                lastTagMsg[0].scrollTop = lastTagMsg[0].scrollHeight
            }
        }

        this.onSocketChat(userId)

    }

    getMessageUser = async (id, lim) => {
        const { endpoint } = this.state
        const data = await axios({
            method: "GET",
            url: `${endpoint}/message?limit=${lim}`,
            headers: { roomId: id }
        })
        return data.data
    }

    handleScrollChat = async (e) => {
        const ele = e.target

        if (ele.scrollTop < 1 && this.fetchMore) {
            this.fetchMore = false
            this.limPage += 10
            const historyMsg = await this.getMessageUser(this.state.roomId, this.limPage)
            this.setState({ listChat: historyMsg })

            setInterval(() => {
                this.fetchMore = true
            }, 700);

            if (ele.scrollHeight > this.scrollChat) {
                ele.scroll(0, ele.scrollHeight - (this.scrollChat == 0 ? ele.scrollHeight - 300 : this.scrollChat))
                this.scrollChat = ele.scrollHeight
            }

        }
    }

    // รอรับข้อมูลเมื่อ server มีการ update
    onSocketChat = (userId) => {
        const { endpoint } = this.state
        const socket = socketIOClient(endpoint)
        this.connSocket = socket.on(userId, (messageNew) => {
            const temp = [...this.state.listChat].filter(function (value) { return !value.mockUp })
            temp.push(messageNew)
            this.setState({ listChat: temp })
            const lastTagMsg = document.getElementsByClassName('wd-he-toscroll')
            if ((lastTagMsg && this.limPage == 10) || (lastTagMsg && messageNew.replyMsg)) {
                lastTagMsg[0].scrollTop = lastTagMsg[0].scrollHeight
            }
        })
    }

    onChangeInputData = (e) => {
        this.setState({
            msgData: e.target.value
        })
    }

    onClickAddData = async () => {
        this.setState({
            msgData: ""
        })

        if (this.state.msgData != "" && this.state.msgData != undefined) {
            const mocKData = [...this.state.listChat]
            mocKData.push({
                mockUp: true,
                replyMsg: true,
                type: "text",
                createdAt: null,
                msg: this.state.msgData
            })

            this.setState({
                listChat: mocKData
            })

            const data = {
                message: this.state.msgData,
                id: this.props.id,
                type: "text",
                platform: this.props.dataUser[this.props.id].platform,
                sender: Cookies.get('userName')
            }

            await sendDataToLine(data)

        }
    }

    keyPressed = (e) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            e.preventDefault()
            this.state.typeInput == "text" ? this.onClickAddData() : this.onClickUploadToServer()
        }
    }

    handleImageChange = async (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            const file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imgPreviewUrl: reader.result,
                    typeInput: "image"
                })
            }

            reader.readAsDataURL(file)
        }
    }

    cancelSendImg = () => {
        this.setState({
            file: "",
            imgPreviewUrl: "",
            typeInput: "text"
        })
    }

    onClickUploadToServer = async () => {
        const path = `${api.MEDIA_API}/upload`

        const formData = new FormData()
        formData.append('file', this.state.file);
        formData.append('serviceKey', api.MEDIA_SERVICE_KEY);
        formData.append('secretKey', api.MEDIA_SECRET_KEY);

        const upload = await axios.post(path, formData)
        const urlImg = upload.data.fileName

        this.setState({
            file: "",
            imgPreviewUrl: "",
            typeInput: "text"
        })

        const data = {
            urlImage: urlImg,
            id: this.props.id,
            type: "image",
            platform: this.props.dataUser[this.props.id].platform,
            sender: Cookies.get('userName')
        }

        await sendDataToLine(data)
        createUploadMediaLog({
            path: upload.data.fileName,
            name: upload.data.originalFileName,
            userId: Cookies.get('user_id'),
        })

    }

    checkMsgType = (msgData, index, refId) => {
        if (msgData.replyMsg) {
            switch (msgData.type) {
                case "text":
                    return (
                        <div key={index} className="div-msg-call-center" id={refId}>

                            <div className="wrap-time">
                                <span>{msgData.createdAt ? convertTimeStampToFormant(msgData.createdAt) : null}</span>
                                <span>{msgData.sender || "..."}</span>
                            </div>

                            <div className="box-msg-call-center" dangerouslySetInnerHTML={{ __html: msgData.msg.replace(/[\r\n]/g, "<br>") }}></div>

                        </div>
                    )

                case "image":
                    return (
                        <div key={index} className="div-msg-call-center" id={refId}>

                            <div className="wrap-time">
                                <span>{msgData.createdAt ? convertTimeStampToFormant(msgData.createdAt) : null}</span>
                                <span>{msgData.sender || "..."}</span>
                            </div>

                            <div className="box-msg-call-center pl-0 pr-0" onClick={() => this.showModalPreview(pathImage + '0x900/image/' + msgData.msg)}>
                                <img src={pathImage + '250x0/image/' + msgData.msg} />

                            </div>
                        </div>
                    )

                case "video":
                    return (
                        <video width="320" height="240" controls id={refId}>
                            <source src={pathVideo + msgData.msg} type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    )

                case "audio":
                    return (
                        <audio width="320" height="240" controls id={refId}>
                            <source src="horse.ogg" type="audio/ogg" />
                            <source src="horse.mp3" type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    )

                case "location":
                    return (
                        <div className="div-msg-call-center" key={index} id={refId}>
                            <div className="container-map">

                                <div className="div-map w-50 d-flex justify-content-end">
                                    <GoogleMap
                                        lat={parseFloat(msgData.locate.latitude)}
                                        lng={parseFloat(msgData.locate.longitude)}
                                        show={false}
                                    />
                                </div>

                                <div className="d-flex justify-content-end mt-3">{`${msgData.locate.latitude}, ${msgData.locate.longitude}`}</div>

                                <div className="d-flex justify-content-end mt-2">
                                    <span>{convertTimeStampToFormant(msgData.createdAt)}</span>
                                    <div className="box-msg-call-center">
                                        {msgData.locate.address}
                                    </div>
                                </div>

                            </div>

                        </div>
                    )

                default:
                    break;
            }

        } else {
            switch (msgData.type) {
                case "text":
                    return (
                        <div className="div-msg-customer" key={index} id={refId}>

                            <div className="box-img-customer">
                                <img src={this.props.dataUser[this.props.id].urlImg} />
                            </div>

                            <div className="box-msg-customer">
                                <div className="box-msg-customer" dangerouslySetInnerHTML={{ __html: msgData.msg.replace(/[\r\n\,]/g, "<br>") }}></div>
                                <span>{convertTimeStampToFormant(msgData.createdAt)}</span>
                            </div>

                        </div>
                    )

                case "image":
                    return (
                        <div key={index} id={refId}>

                            <div className="div-msg-customer">

                                <div className="box-img-customer">
                                    <img src={this.props.dataUser[this.props.id].urlImg} />
                                </div>

                                <div className="box-msg-customer" onClick={() => this.showModalPreview(pathImage + '0x900/image/' + msgData.msg)}>
                                    <img src={pathImage + '250x0/image/' + msgData.msg} />
                                    <span>{convertTimeStampToFormant(msgData.createdAt)}</span>
                                </div>

                            </div>

                            <div className="wrap-btn-file-chat">
                                <button onClick={() => this.onClickDownloadImage(pathImage + 'image/' + msgData.msg, msgData.msg)} className="btn btn-download">ดาวน์โหลด</button>
                                {msgData.uploadTime ? <button disabled className="btn btn-img-upload"> {`อัพโหลดสลิปแล้ว (${convertTimeStampToFormant(msgData.uploadTime)}) `} </button> : <button onClick={() => this.showModalInvoice(pathImage + 'image/' + msgData.msg, msgData._id)} className="btn btn-upload">อัพโหลดสลิป</button>}

                            </div>

                        </div>
                    )

                case "sticker":
                    return (
                        <div className="div-msg-customer" key={index} id={refId}>

                            <div className="box-img-customer">
                                <img src={this.props.dataUser[this.props.id].urlImg} />
                            </div>

                            <div className="box-msg-customer">
                                <img src={msgData.msg} className="w-sticker" />
                                <span>{convertTimeStampToFormant(msgData.createdAt)}</span>
                            </div>

                        </div>
                    )

                case "video":
                    return (
                        <div className="div-msg-customer" key={index} id={refId}>

                            <div className="box-img-customer">
                                <img src={this.props.dataUser[this.props.id].urlImg} />
                            </div>

                            <video width="320" height="240" controls>
                                <source src={pathVideo + msgData.msg} type="video/mp4" />
                                <source src="movie.ogg" type="video/ogg" />
                                Your browser does not support the video tag.
                                </video>

                        </div>
                    )

                case "audio":
                    return (
                        <div className="div-msg-customer" key={index} id={refId}>

                            <div className="box-img-customer">
                                <img src={this.props.dataUser[this.props.id].urlImg} />
                            </div>

                            <audio controls>
                                <source src="horse.ogg" type="audio/ogg" />
                                <source src={pathVideo + msgData.msg} type="audio/mpeg" />
                                Your browser does not support the audio element.
                                </audio>

                        </div>
                    )

                case "location":
                    return (
                        <div className="div-msg-customer" key={index} id={refId}>

                            <div className="box-img-customer">
                                <img src={this.props.dataUser[this.props.id].urlImg} />
                            </div>

                            <div className="container-map">
                                <div className="div-map">
                                    <GoogleMap
                                        lat={parseFloat(msgData.locate.latitude)}
                                        lng={parseFloat(msgData.locate.longitude)}
                                        show={false}
                                    />
                                </div>

                                <div className="d-flex mt-2 copy-clipboard">
                                    {msgData.locate.latitude}, {msgData.locate.longitude}
                                    <CopyToClipboard
                                        text={`${msgData.locate.latitude}, ${msgData.locate.longitude}`}
                                    >
                                        <button className="follow-up ml-2 copy-clipboard">คัดลอก</button>
                                    </CopyToClipboard>
                                </div>

                                <div className="box-msg-customer mt-2">
                                    <div className="box-msg-customer">
                                        {msgData.locate.address}
                                    </div>
                                    <span>{convertTimeStampToFormant(msgData.createdAt)}</span>
                                </div>

                            </div>

                        </div>
                    )

                default:
                    break;
            }

        }

    }

    goToOrder = async () => {
        const code = await genCode(this.props.id)
        window.open(`/all-menu?idCode=${code}`, '_blank')
    }

    onClickDownloadImage = (url, fileName) => {
        saveAs(url, fileName);
    }

    onChangeOpenMadalGallery = () => {
        this.setState({
            isShowModalSelectGallery: true
        })
    }

    closeModalSelect = () => {
        this.setState({
            isShowModalSelectGallery: false
        })
    }

    render() {
        return (
            <div className="wrap-div-chat">
                {
                    this.props.isShowChat ?
                        <div className="div-chat">

                            {
                                this.props.dataUser[this.props.id] ?
                                    <div className="customer-haeder">
                                        <div className="profile">
                                            <div className="status-box">
                                                <div className="social">
                                                    <span className={this.props.platform == "line" ? "line" : this.props.platform == "facebook" ? "facebook" : "phone"}>
                                                        <i className={this.props.platform == "line" ? "icon-line" : this.props.platform == "facebook" ? "icon-facebook" : "icon-phone"} />
                                                    </span>
                                                    <label>{this.props.dataUser[this.props.id].name || this.props.dataUser[this.props.id].nickName}</label>
                                                </div>
                                                <div className="input-group">
                                                    <div>
                                                        <button className="btn btn-edit" type="button" onClick={this.showModalTag} ><i className="icon-tag pr-1" />Tags</button>
                                                        {
                                                            this.state.tagSelectShow.map((tag, index) =>
                                                                <span key={tag.name} className="span-tags" style={{ backgroundColor: tag.colorId.code }}>{tag.name}</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="haeder-right">
                                            {/* <button className="btn btn-search"><i className="icon-search"/></button> */}
                                            <button className="btn btn-primary-sr" onClick={this.goToOrder} disabled={this.props.dataUser[this.props.id].nickName && this.props.dataUser[this.props.id].mobile ? false : true}>สร้างออเดอร์</button>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.props.dataUser[this.props.id] ?
                                    <div onScroll={this.handleScrollChat}>
                                        <div className="line-haeder" />

                                        <div className={this.props.dataUser[this.props.id].announce ? "div-list-data pt-5" : "div-list-data"}>

                                            {
                                                this.props.dataUser[this.props.id].announce ?
                                                    <div className="announce">
                                                        <label className="text-announce">
                                                            <span className="haeder">ประกาศ :</span>
                                                            {this.props.announceMsg}
                                                        </label>
                                                    </div>
                                                    : null
                                            }

                                            <div className="wd-he-toscroll">
                                                {
                                                    this.state.listChat.map((data, index) =>
                                                        data.mockUp ? this.checkMsgType(data, index) : this.checkMsgType(data, index, data._id)
                                                    )
                                                }
                                                {/* <div className="cover-bar" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.props.dataUser[this.props.id] ?
                                    <div className="chat-box">
                                        <div className="input-group" onKeyPress={this.keyPressed}>
                                            {
                                                this.state.typeInput == "text" ?
                                                    <div>
                                                        <button className="btn btn-attach" type="button">
                                                            <img className="imgcloud" src={imgcloud} alt='img-cloud' onClick={this.onChangeOpenMadalGallery} />
                                                        </button>

                                                        <button className="btn btn-attach" type="button"><i className="icon-photo" />
                                                            <input type="file" onChange={(e) => this.handleImageChange(e)} />
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                this.state.typeInput == "text" ?
                                                    <textarea type="textarea" rows="4" className="form-control mr-1" onChange={this.onChangeInputData} value={this.state.msgData} />
                                                    :
                                                    <div className="attach-img" onKeyPress={this.keyPressed}>
                                                        <div className="img-upload">
                                                            <img src={this.state.imgPreviewUrl} />
                                                        </div>
                                                        <div className="box-file-name">
                                                            <label>{this.state.file.name}</label>
                                                            <button className="btn btn-close" type="button" onClick={this.cancelSendImg}><i className="icon-close" /></button>
                                                        </div>
                                                    </div>
                                            }

                                            {
                                                this.state.typeInput == "text" ?
                                                    null
                                                    :
                                                    <div className="input-group-append">
                                                        <button className="btn btn-send" type="button" id="button-addon2" onClick={this.state.typeInput == "text" ? this.onClickAddData : this.onClickUploadToServer}><i className="icon-sent" />ส่ง</button>
                                                    </div>
                                            }
                                            {/* <div className="input-group-append">
                                                <button className="btn btn-send" type="button" id="button-addon2" onClick={this.state.typeInput == "text" ? this.onClickAddData : this.onClickUploadToServer}><i className="icon-sent" />ส่ง</button>
                                            </div> */}
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                        :
                        <div className="box-frist">
                            <img src={ArrowLrft} />

                            <div className="box-text">
                                <label>เลือกลูกค้า </label>
                                <label>เพื่อเริ่มการแชท</label>
                            </div>

                        </div>
                }

                <ModalTag
                    showModalTag={this.state.isShowModalTag}
                    closeModalTag={this.closeModalTag}
                    refresh={this.getTagShow}
                    id={this.props.id}
                    tagSelectShow={this.state.tagSelectShow}
                />

                <ModalPreview
                    showModalPreview={this.state.isShowModalPreview}
                    closeModalPreview={this.closeModalPreview}
                    imagePreview={this.state.imagePreview}
                />

                <ModalManageInvoice
                    showModalInvoice={this.state.isShowModalInvoice}
                    closeModalInvoice={this.closeModalInvoice}
                    slip={this.state.slip}
                    id={this.props.platform == 'mobile' ? this.props.dataUser && this.props.dataUser[this.props.id].mobile : this.props.id}
                    name={this.props.dataUser[this.props.id] ? this.props.dataUser[this.props.id].name || this.props.dataUser[this.props.id].nickName : ""}
                    platform={this.props.dataUser[this.props.id] ? this.props.dataUser[this.props.id].platform : ""}
                    idMsg={this.state.idMsg}
                    refresh={this.list}
                />

                <ModalSelectMedia
                    showModalSelect={this.state.isShowModalSelectGallery}
                    closeModalSelect={this.closeModalSelect}
                    id={this.props.platform == 'mobile' ? this.props.dataUser && this.props.dataUser[this.props.id].mobile : this.props.id}
                    platform={this.props.dataUser[this.props.id] ? this.props.dataUser[this.props.id].platform : ""}
                />

            </div>
        )
    }
}

export default ListChat