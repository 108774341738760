import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import '../../styles/chat/modal.scss'

class ModalDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,

        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modal: nextProps.showModalDelete,
        })
    }

    closeModal = () => {
        this.props.closeModalDelete()
    }

    onClickRemove = () => {
        if(this.props.albumId){
            this.props.deletedAlbum(this.props.albumId)
        }else{
            this.props.mediaSelect.map(media => {
                this.props.deletedMedia(media._id)
            })
        }
    }

    render() {
        return (
            <Modal className="modal-delete align-self-center" isOpen={this.state.modal}>
                <ModalBody>
                    <div className="box-modaldelete">
                        <h4><strong>ต้องการลบ?</strong></h4>
                        <div className="row confirm">
                                <button className="col-12 btn-accept" onClick={this.onClickRemove}>ยืนยัน</button>
                                <button className="col-12 btn-reject" onClick={this.closeModal}>ยกเลิก</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalDelete