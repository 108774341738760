import axios_saranros from '../lib/axios-saranros';
import api from '../config/api';
import * as util from '../utils/utils';

class InvoiceCtrl {


    sumPriceFood = (listMenuSelects) => {
        const total = Object.keys(listMenuSelects).reduce(function (previous, key) { // for loop sum total price
            const result = parseFloat(previous + (listMenuSelects[key].quantity * (listMenuSelects[key].product && listMenuSelects[key].product.dataProduct[0].priceNet)));
            if (isNaN(result)) {
                return 0
            }
            return result
        }, 0);
        return total
    };
    showPriceFoodDiscount = (listMenuSelects) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { priceSpecialDiscount } = listMenuSelects.updateInvoiceById
                let foodDiscount = priceSpecialDiscount != null && priceSpecialDiscount.foodDiscount ? parseFloat(priceSpecialDiscount.foodDiscount) : 0
                const result = this.sumPriceFood(listMenuSelects.listMenuSelect)
                if (priceSpecialDiscount != null) {
                    return result - foodDiscount
                }
                resolve(result)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
       
    }


    checkShippingCostNoOrder = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = api.SARANROS_API + '/api/Invoices/v3/checkShippingNoOrder';
                const result = axios_saranros.post(url, { ...params })
                resolve(result)
            } catch (error) {
                reject(error);
            }
        })
    }

    listInvoiceByUserId = async (userId, status) => { // status has 3 => [wait, waitDelivery, completed]
        try {
            const path = `${api.SARANROS_API}/api/Invoices/v2/user/${userId}?status=${status}`
            const listInvoice = await axios_saranros.get(path)
            return listInvoice.data

        } catch (error) {
            return error
        }
    }

    listInvoiceByPaymentStatusSuccess = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {

                const { userId, pagination, search } = params;

                if (search.transid !== "") {
                    const filter = {
                        where: {
                            paymentStatus: "success",
                            userId
                        },
                        include: [{
                            relation: 'purchase',
                            scope: {
                                where: {
                                    transid: {
                                        regexp:
                                            search.transid
                                    }
                                }
                            }
                        }],
                    }

                    const url = `${api.SARANROS_API}/api/Invoices?filter=${JSON.stringify(filter)}`;
                    const res_list = await axios_saranros.get(url);
                    const filter_list = res_list.data.filter(item => item.purchase);

                    const new_data = [];

                    for (let key in filter_list) {
                        const find_invoice = new_data.find((item) => item.purchase.transid === filter_list[key].purchase.transid);
                        if (find_invoice == null) {
                            new_data.push(filter_list[key]);
                        }
                    }
                    const { start, end } = util.calculatePagination(pagination.currentPage + 1, pagination.pageSize);
                    const slice_list = new_data.slice(start, end);


                    resolve({
                        list: slice_list,
                        count: filter_list.length
                    });

                } else {
                    const filter = {
                        where: {
                            paymentStatus: "success",
                            userId
                        },
                        // limit: pagination.pageSize,
                        // skip: pagination.skip,
                        include: [{
                            relation: 'purchase'
                        }],
                    }

                    const url = `${api.SARANROS_API}/api/Invoices?filter=${JSON.stringify(filter)}`;




                    const res_list = await axios_saranros.get(url);
                    const copy_list = [...res_list.data];
                    const new_data = [];


                    for (let key in copy_list) {
                        const find_invoice = new_data.find((item) => item.purchase.transid === copy_list[key].purchase.transid);
                        if (find_invoice == null) {
                            new_data.push(copy_list[key]);
                        }
                    }
                    const { start, end } = util.calculatePagination(pagination.currentPage + 1, pagination.pageSize);
                    const slice_list = new_data.slice(start, end);

                    resolve({
                        list: slice_list,
                        count: new_data.length
                    });
                }

            } catch (error) {
                reject(error);
            }
        });
    }


    listInvoiceByPaymentStatusWait = async (params) => {
        return new Promise(async (resolve, reject) => {

            const { userId } = params;
            try {
                const filter = {
                    where: {
                        paymentStatus: "wait",
                        userId
                    },
                    include: [{
                        relation: 'purchase'
                    }, {
                        relation: 'order',
                        scope: {
                            include: {
                                relation: "product",
                                scope: {
                                    include: [{ relation: "menu" }]
                                }
                            }
                        }
                    }],
                }
                const url = `${api.SARANROS_API}/api/Invoices?filter=${JSON.stringify(filter)}`;

                const { data } = await axios_saranros.get(url);
                resolve(data);
            } catch (error) {
                reject(error)
            }
        });
    }

    getDetailInvoicePaymentStatusSuccess = async (params) => {
        return new Promise(async (resolve, reject) => {
            const { transid } = params;
            try {
                const filter = {
                    where: {
                        transid: transid, status: 'success'
                    },
                    include: [
                        {
                            relation: 'invoice',
                            scope: {
                                include: {
                                    relation: 'order',
                                    scope: {
                                        include: {
                                            relation: "product",
                                            scope: {
                                                include: [{ relation: "menu" }]
                                            }
                                        }
                                    }
                                },
                                include: {
                                    relation: 'purchase',
                                    scope: {
                                        include: {
                                            relation : 'purchaseNote'
                                        }
                                    }
                                }
                            },
                        }

                    ]
                }
                const url = `${api.SARANROS_API}/api/Purchases?filter=${JSON.stringify(filter)}`;
                const { data } = await axios_saranros.get(url);
                resolve(data);
            } catch (error) {
                reject(error)
            }
        });
    }

}




export default InvoiceCtrl;