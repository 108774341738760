import axios from 'axios'
import api from '../config/api'

class extraDataCtrl {
    getListMenu = async (params) => {
        const path = api.SARANROS_API + '/api/Menus/SearchMenuByDateTime';
        const query = {
            menuName: params && params.menu || "",
            shopName: params && params.shop || "",
            sortBy: params && params.sortBy || ""
        };

        if (params && !params['sortBy'] && params != "undefined") {
            delete query.sortBy
        }

        try {
            const result = await axios.post(path, query, {
                headers: {
                    'platform': 'cms'
                }
            });
            if (result.status == 200) {
                return result.data
            }

        } catch (e) {
            console.log('error', e)
        }
    };

    getListShop = async () => {
        const path = api.SARANROS_API + '/api/Shops?filter='
        const filter = {
            where: {

            }
        }
        try {
            const result = await axios.get(path)
            if (result.status == 200) {
                return result.data
            }
        } catch (error) {
            console.log(error);

        }
    }

    getListMenuOptions = async (shopId) => {
        const path = api.SARANROS_API + '/api/Menus?filter='
        const filter = {
            where: {
                shopId,
                isActive: 'true',
                isComingSoon: 'false'
            },
            include: {
                relation: 'shop'
            }
        }

        if (shopId == "" || shopId === null) {
            delete filter.where.shopId
        }

        try {
            const result = await axios.get(path + JSON.stringify(filter))
            return result.data

        } catch (error) {
            console.log(error);

        }
    }

    downloadPDF = async (listProduct) => {
        return new Promise(async (resolve, reject) => {
            try {
                const path = api.SARANROS_API + '/api/MenuExtraData/download'
                const result = await axios.post(path, listProduct)
                resolve(result.data)

            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }

    getStatusDownloadPDF = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const path = api.SARANROS_API + `/api/MenuExtraData/download/${id}/status`
                const result = await axios.get(path)
                resolve(result.data)
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }

    pathDownloadPDF = (id) => {
        return api.SARANROS_API + `/api/MenuExtraData/download/${id}`
    }

    deletePDFDownload = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const path = api.SARANROS_API + `/api/MenuExtraData/download/${id}`
                const result = axios.delete(path)
                resolve(result.data)
            } catch (error) {
                console.log(error);
                reject(error)
            }

        })
    }



}

export default extraDataCtrl