import React from 'react';
import { loginRequest } from '../redux/actions/users'
import { connect } from 'react-redux';
import IamgeLogo from '../assets/Icons/Logo.svg';
class App extends React.Component {
  state = {
    username: '',
    password: '',
    error: {
      username: false,
      password: false,
    }
  }

  baseState = { ...this.state };

  handleSubmitLogin = async (evt) => {
    evt.preventDefault();
    const isValidate = await this.validateFrom();
    if (isValidate) {
      // if (evt.key === "Enter") {
      this.props.loginRequest({ ...this.state });
      // } else {
      //   evt.preventDefault();
      //   this.props.loginRequest({ ...this.state });
      // }
    }

  };

  componentWillUnmount() {
    this.setState({
      ...this.baseState
    })
  }

  validateFrom = async () => {
    let isValidateSuccess = true;
    if (this.state.username.trim().length === 0) {
      isValidateSuccess = false;
      await this.setState({
        error: {
          ...this.state.error,
          username: true
        }
      });
    } else {
      await this.setState({
        error: {
          ...this.state.error,
          username: false
        }
      });
    }

    if (this.state.password.trim().length === 0) {
      isValidateSuccess = false;
      await this.setState({
        error: {
          ...this.state.error,
          password: true
        }
      });
    } else {
      await this.setState({
        error: {
          ...this.state.error,
          password: false
        }
      });
    }
    return isValidateSuccess;
  }


  handleOnchangeText = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };


  render() {
    const { error } = this.props;
    return (
      <div id="login-page">
        <div className="wrap">
          <div className="login-form">


            <div className="container mt-50px">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-7">

                  <img src={IamgeLogo} alt="IamgeLogo" />
                </div>
                <div className="col-md-5">
                  <form onSubmit={this.handleSubmitLogin}>
                    <h3>เข้าสู่ระบบ</h3>
                    <div className="form-group">
                      <label>ชื่อผู้ใช้</label>
                      <input type="text"
                        className={`form-control ${error.isError && `border-color-error`}`}
                        onChange={this.handleOnchangeText}
                        value={this.state.username}
                        placeholder="ชื่อผู้ใช้"
                        name="username"
                      />
                      {(this.state.error.username || error.isError) && <span className="font-color-error font-size-14px">*กรุณากรอกอีเมล</span>}
                    </div>
                    <div className="form-group">
                      <label>รหัสผ่าน</label>
                      <input type="password"
                        className={`form-control ${error.isError && `border-color-error`}`}
                        value={this.state.password}
                        name="password"
                        onChange={this.handleOnchangeText}
                        placeholder="รหัสผ่าน" />
                      {(this.state.error.password || error.isError) && <span className="font-color-error font-size-14px">*กรุณากรอกรหัสผ่าน</span>}

                    </div>
                    {/* <span className="font-color-error">{error.isError && `* ${error.message}`}</span> */}
                    <button type="submit" value="Login"
                      className="btn btn-primary w-100 color-primary btn-primary mt-2"
                      onClick={this.handleSubmitLogin}
                    >เข้าสู่ระบบ</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



const mapDispatchToProps = dispatch => ({
  loginRequest: ({ username, password }) => dispatch(loginRequest({ username, password }))
})

const mapStateToProps = state => ({
  error: state.users.error
})

export default connect(mapStateToProps, mapDispatchToProps)(App);

