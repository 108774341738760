import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import IconAdd from '../../../assets/Icons/Add.png';
import ContentTabUser from './ContentTabUser';
import ModalSummaryOrder from '../../pages/CreateOrder/ModalSummaryOrder';
import IconSelectMenu from '../../../assets/Icons/IconSelectMenu.svg';
import { connect } from 'react-redux';
import * as actions_createOrder from '../../../redux/actions/createOrder';
import * as actions_logistics from '../../../redux/actions/logistics';
import * as actions_notification from '../../../redux/actions/notification';
import * as actions_loading from '../../../redux/actions/loading';
import * as actions_menus from '../../../redux/actions/menus';
import IconNotification from '../../../assets/Icons/IconAlert.svg';
import Bin from '../../../assets/Icons/bin.png'
import Delete1 from '../../../assets/Icons/delete1.png'
import { Tooltip, UncontrolledTooltip } from 'reactstrap';

const CardEmpty = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <div className="container">
            {props.activeTab > 0 ?

                <div className="row justify-content-between mt-1">
                    <span className="font-primary-16px-text">รอบส่งที่ {props.activeTab + 1}</span>
                    <img src={Bin} alt="bin" className="bin-icon cursor-pointer"
                        onMouseOver={e => (e.currentTarget.src = Delete1)}
                        onMouseOut={e => (e.currentTarget.src = Bin)}
                        id={"tools" + props.idx}
                        onClick={() => props.removeTab(props.activeTab)}
                    />
                    {/* <Tooltip placement="top" target={"tools" + props.idx} isOpen={tooltipOpen} toggle={toggle}>
                        ลบรอบส่งนี้
                        </Tooltip> */}
                    <UncontrolledTooltip placement="top" target={"tools" + props.idx}>
                        ลบรอบส่งนี้
      </UncontrolledTooltip>
                </div>
                : ""}
            <div className="box-caed-empty">
                <img src={IconSelectMenu} alt="IconCardMenuEmpty" />
            </div>
        </div>
    )
}

class TabUser extends React.Component {
    state = {
        activeTab: 0,
        modalSummary: {
            isOpen: false
        },
        specialPrice: ''
    }

    baseState = {
        ...this.state
    }

    componentDidMount() {
        // this.props.getLogistic(); // get list logistic.
    }

    setActiveTab = (tab) => {
        this.props.setActiveTab(tab);
    }

    removeTab = (idx) => {
        this.props.removeTab(idx);
        if (this.props.createOrder.activeTab == 0) {
            this.props.getListMenu(this.props.createOrder.orderShop[this.props.activeTab + 1].search)
        } else {
            this.props.getListMenu(this.props.createOrder.orderShop[this.props.activeTab - 1].search)
        }
    }

    openModalSummaryOrder = async () => {
        const isValiDateFrom = await this.validateFrom();
        if (isValiDateFrom) {
            this.setState({
                modalSummary: {
                    isOpen: true
                }
            });
        }
    }

    closeModalSummaryOrder = () => {
        this.setState({
            modalSummary: {
                ...this.baseState.modalSummary
            }
        });
    }

    addTab = async () => {
        const { orderShop } = this.props;
        const orderDefault = orderShop[0];
        if (Object.keys(orderDefault.listMenuSelect).length === 0 || orderDefault.checkShippingCosts.deliveryAt[0].subdistrict == null || orderDefault.checkShippingCosts.deliveryAt[0].district == null || orderDefault.checkShippingCosts.deliveryAt[0].province == null || orderDefault.checkShippingCosts.deliveryAt[0].zipcode == "" || orderDefault.checkShippingCosts.deliveryAt[0].address == "" || orderDefault.checkShippingCosts.deliveryAt[0].location.length == 0) {
            return;
        }
        this.props.addTab();
        this.props.setActiveTab(this.props.orderShop.length);
        this.props.setDefaultSelectShopMenu()
    }

    setPriceSpecial = (price) => {
        this.setState({
            specialPrice : price
        })
    }



    validateFrom = async () => {
        const { orderShop } = this.props;
        let i;
        let isSusscess = true;
        const reg = new RegExp('^[0-9]+$');
        const reg_price = /^\d+(\.\d+)?$/;
        for (i in orderShop) {
            const this_order = orderShop[i];
            if (Object.keys(this_order.listMenuSelect).length !== 0) {
                const createUser = this_order.createUser;
                if (createUser.name.trim().length === 0) {
                    await this.openNotificationAlert('ยังไม่ได้ระบุชื่อผู้รับ', i);
                    isSusscess = false;
                }
                if (createUser.mobile.trim().length === 0) {
                    await this.openNotificationAlert('ยังไม่ได้ระบุเบอร์ผู้รับ', i);
                    isSusscess = false;
                } else {
                    if (createUser.mobile.trim().length > 10 || createUser.mobile.trim().length < 9 || !reg.test(createUser.mobile)) {
                        await this.openNotificationAlert('เบอร์ผู้รับไม่ถูกต้อง กรุณาตรวจทานอีกครั้ง', i);
                        isSusscess = false;
                    }
                }
                if(this_order.deliveryOption.trim().length == 0) {
                    await this.openNotificationAlert('กรุณาเลือกขนส่ง', i);
                    isSusscess = false;
                }
                if(this_order.deliveryOption == "SPECIAL") {
                    if(this_order.checkShippingCosts.deliveryNote.trim().length == 0) {
                        await this.openNotificationAlert('กรุณาระบุขนส่งพิเศษ', i);
                        isSusscess = false;
                    }
                    if(this_order.priceSpecial.toString().trim().length == 0) {
                        await this.openNotificationAlert('กรุณากรอกค่าขนส่งพิเศษ', i);
                        isSusscess = false;
                    }
                }
                const deliveryAt = this_order.checkShippingCosts.deliveryAt[0];
                if (deliveryAt.address.trim().length === 0 || deliveryAt.location[0] == null || deliveryAt.location[1] == null) {
                    await this.openNotificationAlert('ยังไม่ได้ระบุที่อยู่ในการจัดส่ง', i);
                    isSusscess = false;
                }
                if (deliveryAt.dateDelivery.trim().length === 0) {
                    await this.openNotificationAlert('ยังไม่ได้ระบุวันเวลาจัดส่งที่ลูกค้า', i);
                    isSusscess = false;
                }
                if (deliveryAt.province == null) {
                    await this.openNotificationAlert('กรุณาเลือกจังหวัด', i);
                    isSusscess = false;
                }
                if (deliveryAt.district == null) {
                    await this.openNotificationAlert('กรุณาเลือกเขต', i);
                    isSusscess = false;
                }
                if (deliveryAt.subdistrict == null) {
                    await this.openNotificationAlert('กรุณาเลือกแขวง', i);
                    isSusscess = false;
                }
                if (deliveryAt.zipcode.toString().trim().length !== 5 || !reg.test(deliveryAt.zipcode)) {
                    await this.openNotificationAlert('กรุณากรอกรหัสไปรษณีย์ใหถูกต้อง', i);
                    isSusscess = false;
                }

                if (deliveryAt && !deliveryAt.dateDelivery) {
                    await this.openNotificationAlert('ยังไม่ได้ระบุวันเวลาจัดส่งที่ลูกค้า', i);
                    isSusscess = false;
                }

                const errors_outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
                if (errors_outOfServiceArea && this_order.deliveryOption != "SPECIAL") {
                    await this.openNotificationAlert('ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ', i);
                    isSusscess = false;
                }
                if (this_order.deliveryOption === "SPECIAL" && !reg_price.test(orderShop[i].shippingCost.deliveryPrice)) {
                    await this.openNotificationAlert('กรุณากรอกให้ถูกต้อง', i);
                    isSusscess = false;
                }
                const priceSpecialDiscount = this_order.updateInvoiceById.priceSpecialDiscount;
                if (priceSpecialDiscount !== null) {
                    const errors_specialDiscount = this_order.errors.specialDiscount;
                    if (priceSpecialDiscount.code.trim().length === 0 || priceSpecialDiscount.foodDiscount.toString().trim().length === 0 ||
                        priceSpecialDiscount.note.trim().length === 0 || priceSpecialDiscount.shippingDiscount.toString().trim().length === 0 ||
                        errors_specialDiscount.code || !reg_price.test(priceSpecialDiscount.foodDiscount) || !reg_price.test(priceSpecialDiscount.shippingDiscount)) {
                        await this.openNotificationAlert('กรุณากรอกข้อมูลส่วนลดพิเศษให้ถูกต้อง', i);
                        isSusscess = false;
                    }
                }
            } else {
                await this.openNotificationAlert('กรุณาเลือกเมนู', i);
                isSusscess = false;
            }

        }
        return isSusscess;
    }

    openNotificationAlert = (message, idx) => {
        const data = {
            message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {parseInt(idx) + 1} {message}</span></div>,
            level: 'error',
            autoDismiss: 0,
            position: "tr"
        }
        this.props.openNotificationAlert(data);
    }


    checkDisableButtonCheckout = () => {
        const { orderShop } = this.props;
        const orderDefault = orderShop[0];
        if (Object.keys(orderDefault.listMenuSelect).length === 0 || orderShop[this.props.activeTab].checkShippingCosts.deliveryAt[0].dateDelivery == null) {
            return true;
        }
        return false;
    }

    render() {
        const { orderShop, activeTab } = this.props;
        
        return (
            <div className="component-tap">
                <Nav tabs>

                    {
                        orderShop.map((item, idx) =>
                            <NavItem key={idx}>
                                <NavLink
                                    className={classnames({ "box-number-tab-active": activeTab === idx, "box-number-tab-in-active": activeTab !== idx, "cursor-pointer": true, "ml-2px": idx !== 0 })}
                                    onClick={() => { this.setActiveTab(idx); }}
                                >
                                    <label className="font-number-tab-user">{idx + 1}</label>
                                </NavLink>
                            </NavItem>
                        )
                    }

                    <NavItem onClick={this.addTab} className="cursor-pointer">
                        <NavLink
                            className="box-number-tab-in-active ml-2px d-flex align-items-center justify-content-center"
                        >
                            <img className="size-icon-add" src={IconAdd} alt="IconAdd" />

                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="box-tab-content">
                    <div className="inner">
                        <TabContent activeTab={activeTab}>
                            {
                                orderShop.map((item, idx) =>
                                    <TabPane tabId={idx} key={idx}>
                                        {
                                            Object.keys(item.listMenuSelect).length === 0 ?
                                                <CardEmpty activeTab={this.props.activeTab}
                                                    removeTab={this.removeTab}
                                                    idx={idx}
                                                />
                                                :
                                                <ContentTabUser
                                                    idx={idx}
                                                    item={item}
                                                    countItemAll={orderShop.length}
                                                    // setPriceSpecial={this.setPriceSpecial}
                                                />

                                        }
                                    </TabPane>
                                )}
                        </TabContent>
                        <div className="cover-bar" />
                    </div>
                    <div className="box-button-checkout">
                        <button
                            type="button"
                            className={`btn ${(this.checkDisableButtonCheckout() || (orderShop[activeTab].isDefaultContactCustomer && orderShop[0].isAddressOnChange || orderShop[0].errors_outOfServiceArea || orderShop[0].deliveryOption.trim().length == 0)) ? "btn-darkgrey" : "btn-primary"} w-100`}
                            onClick={(this.checkDisableButtonCheckout() || (orderShop[activeTab].isDefaultContactCustomer && orderShop[0].isAddressOnChange || orderShop[0].errors_outOfServiceArea || orderShop[0].deliveryOption.trim().length == 0)) ? null : this.openModalSummaryOrder}
                        // disabled={orderShop[activeTab].isDefaultContactCustomer && orderShop[0].isAddressOnChange}
                        >เช็คเอาท์</button>
                    </div>
                </div>

                <ModalSummaryOrder
                    closeModalSummaryOrder={this.closeModalSummaryOrder}
                    modalSummary={this.state.modalSummary}
                />
            </div>
        )
    }
}


const mapStateToProps = state => ({
    createOrder: state.createOrder,
    orderShop: state.createOrder.orderShop,
    activeTab: state.createOrder.activeTab
});


const mapDispatchToProps = dispatch => ({
    addTab: () => dispatch(actions_createOrder.addTab()),
    setActiveTab: (idx) => dispatch(actions_createOrder.setActiveTab(idx)),
    getLogistic: () => dispatch(actions_logistics.getListLogistic()),
    openNotificationAlert: (payload) => dispatch(actions_notification.openNotificationAlert(payload)),
    openLoading: () => dispatch(actions_loading.openLoading()),
    closeLoading: () => dispatch(actions_loading.closeLoading()),
    removeTab: (idx) => dispatch(actions_createOrder.removeTab(idx)),
    getListMenu: ({ menu, shop, date, time }) => dispatch(actions_menus.getListMenu({ menu, shop, date, time }))
});


export default connect(mapStateToProps, mapDispatchToProps)(TabUser);