export const SUPERADMIN = 'superadmin';
export const ADMIN = 'admin';
export const CALLCENTER = 'callcenter';
export const CHEF = 'chef';
export const ACCOUNT = 'account';


/// header ///
export const PERRMISSION_HEADER_CHAT = {
    [SUPERADMIN]: SUPERADMIN,
    [CALLCENTER]: CALLCENTER
}
export const PERRMISSION_HEADER_CHECK_TRANFER = {
    [SUPERADMIN]: SUPERADMIN,
    [ADMIN]: ADMIN
}
export const PERRMISSION_HEADER_LIST_ORDER_ALL = {
    [SUPERADMIN]: SUPERADMIN,
    [ADMIN]: ADMIN,
    [CALLCENTER]: CALLCENTER
}
/// header ///

/// route ///
export const PERRMISSION_ROUTE = {
    [SUPERADMIN]: ['/', '/all-menu', '/check-transfer','/order-history/:id','/extra-data', '/media-gallery'],
    [ADMIN]: ['/check-transfer'],
    [CALLCENTER]: ['/', '/all-menu','/order-history/:id','/extra-data', '/media-gallery']
}
/// route ///