import React, { Component } from 'react';
import ListChat from '../components/chat/listChat'
import ListUser from '../components/chat/listUser'
import SideBarDetail from '../components/chat/sideBarDetail'
import '../styles/chat/chatRoom.scss'

class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowChat: false,
            id: '',
            dataUser: {},
            readMsg: false,
            pin: false,
            announce: false,
            announceMsg: "",
            platform: ""
        }
    }

    setIdChat = (id) => {
        this.setState({
            isShowChat: true,
            id: id
        })
    }

    setReadChat = (bool) => {
        this.setState({
            readMsg: bool
        })
    }

    setPin = (pin) => {
        this.setState({
            pin: pin
        })
    }

    setAnnounce = (announce) => {
        this.setState({
            announce: announce
        })
    }
    
    setAnnounceMsg = (msg) => {
        this.setState({
            announceMsg: msg
        })
    }

    setDataUser = async (data) => {
        let userArrange = {}

        await Promise.all(
            await data.map((user, index) => {
                userArrange[user.userId] = user
            })
        )

        this.setState({
            dataUser: userArrange
        })
    }

    setPlatform = (platform) => {
        this.setState({
            platform: platform
        })
    }

    disableChat = () => {
        this.setState({
            isShowChat: false,
            id: '',
            dataUser: {},
            pin: false,
            announce: false,
            announceMsg: "",
            platform: ""
        })
    }

    updateDataUser = (data) => {
        this.setState({
            dataUser: data
        })
    }

    render() {
        return (
            <div>
                <div className="div-all">
                    <div className="div-list-user">
                        <ListUser
                            setIdChat={this.setIdChat}
                            setDataUser={this.setDataUser}
                            setReadChat={this.setReadChat}
                            idSelected={this.state.id}
                            disableChat={this.disableChat}
                            setPin={this.setPin}
                            setAnnounce={this.setAnnounce}
                            setAnnounceMsg={this.setAnnounceMsg}
                            setPlatform={this.setPlatform}
                        />
                    </div>

                    <div className="div-list-chat">
                        <ListChat
                            isShowChat={this.state.isShowChat}
                            id={this.state.id}
                            dataUser={this.state.dataUser}
                            announce={this.state.announce}
                            announceMsg={this.state.announceMsg}
                            platform={this.state.platform}
                        />
                    </div>
                    
                        <SideBarDetail
                            isShowChat={this.state.isShowChat}
                            dataUser={this.state.dataUser}
                            id={this.state.id}
                            pin={this.state.pin}
                            setPin={this.setPin}
                            announce={this.state.announce}
                            setAnnounce={this.setAnnounce}
                            announceMsg={this.state.announceMsg}
                            setAnnounceMsg={this.setAnnounceMsg}
                            platform={this.state.platform}
                            updateDataUser={this.updateDataUser}
                        />


                </div>
            </div>
        )
    }

}

export default Chat