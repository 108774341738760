import React from 'react';

import Select from 'react-select';


const SelectComponent = ({ placeholder, options, onChange, value, getOptionLabel, getOptionValue, disabled }) => {
    return <div id="select-option-component" className="w-100">
        <Select
            options={options}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isDisabled={disabled}
        />
    </div>
}

export default SelectComponent;