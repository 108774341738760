import React from 'react';
import CheckBoxActive from '../../../assets/Icons/checkBoxActive.png';
import CheckBoxInActive from '../../../assets/Icons/checkBoxInActive.png';
import { connect } from 'react-redux'
import * as actions_createOrder from '../../../redux/actions/createOrder';
import * as actions_menus from '../../../redux/actions/menus';
import api from '../../../config/api';
import moment from 'moment';
import * as utils from '../../../utils/utils';
import { UncontrolledTooltip } from 'reactstrap';
import ModalExtraData from './ModalExtraData';


class CardMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checkbox: false,
            modalExtraData: {
                isOpen: false,
                data: null
            }
        }
    }


    /// insert/remove menu to cart
    onChangeCheckbox = async (detailMenu, idx, mainData, userId) => {
        await this.props.addMenuToCart({ detailMenu, idx, mainData, productId: detailMenu.dataProduct[0]._id, datePreorder: this.props.orderShop[idx].checkShippingCosts.deliveryAt[0].dateDelivery || moment().toISOString(), userId: userId })

        // await this.props.checkListDateTime({ productId: detailMenu.dataProduct[0]._id, datePreorder: "2019-10-30T17:00:00.000Z", userId: "5bd01131465a0a7a14ebdf35", idx })

        // if(Object.keys(this.props.createOrder.orderShop[idx].listMenuSelect).length == 0 ){
        //     const data = {
        //         shop: "",
        //         menu: "",
        //         date: "",
        //         time: "",
        //     }
        //     await this.props.setSearch({data,idx})
        // } 
        // await this.props.getListMenu(this.props.createOrder.orderShop[idx].search)
    }


    ///count menu in cart
    countMenuInCart = () => {
        const orderShop = this.props.orderShop[this.props.activeTab].listMenuSelect
        return Object.keys(orderShop).length
    }

    closeModalExtraData = () => {
        this.setState({
            modalExtraData: {
                isOpen: false,
                data: null
            }
        })
    }


    // checkStringLengthShopName = (name) => {
    //     if (name.length > 23) {
    //         return name = name.substring(0, 23) + "...";
    //     } else {
    //         return name
    //     }
    // }

    render() {
        const { props } = this;
        const { orderShop, detailMenu, createOrder } = props;
        const dataInTab = orderShop[props.activeTab];
        const { listMenuSelect } = dataInTab;
        const product = detailMenu.dataProduct[0];
        // const pathImage = "https://secure-media.saranros.com"

        return (
            <div className="col-md-4 pt-15 cursor-pointer">
                <div className="card card-menu">
                    {
                        dataInTab.mainData && dataInTab.mainData.shopId !== detailMenu.shopId && Object.keys(listMenuSelect).length !== 0 ? <div className="disable-card-product" />
                            : null
                    }
                    <div onClick={dataInTab.mainData && dataInTab.mainData.shopId !== detailMenu.shopId && Object.keys(listMenuSelect).length !== 0 ? null : () => this.onChangeCheckbox(props.detailMenu, props.activeTab, props.mainData, createOrder.userCustomer.id)}>
                        <img src={
                            `${api.THUMP}/800x600/image/${props.detailMenu.dataProduct[0].cover.fileName}`
                            // `${pathImage}/image/800x600/${props.detailMenu.dataProduct[0].cover.fileName}`
                        } className="card-img-top" alt="..." />
                        <div className="card-body">
                            <div className="wrap-content">
                                <span className="font-size-16px text-break text-overflow cursor-pointer" id={`detailMenu` + detailMenu._id}>{props.detailMenu.name && props.detailMenu.name.th}
                                    {props.detailMenu.name && props.detailMenu.name.th.length > 12 ? <UncontrolledTooltip placement="auto" target={`detailMenu` + detailMenu._id}>
                                        {props.detailMenu.name && props.detailMenu.name.th}
                                    </UncontrolledTooltip> : ""}
                                </span>
                                <div className="select-menu">
                                    <p className="card-text">{utils.numberFormatDecimalTwoPoint(props.detailMenu.dataProduct[0].priceNet)}.-</p>
                                    <img className="size-icon-20px" src={listMenuSelect[product._id] ? CheckBoxActive : CheckBoxInActive} alt="checkbox" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="mt-2 detail-menu" onClick={() => this.setState({ modalExtraData: { isOpen: true, data: { detailMenu: props.detailMenu, mainData: props.mainData } } })}>
                        ดูรายละเอียดเพิ่มเติม
                        </div>
                </div>

                {this.state.modalExtraData.isOpen ?
                    <ModalExtraData
                        modal={this.state.modalExtraData}
                        isClose={this.closeModalExtraData}
                    /> : ""
                }


            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addMenuToCart: ({ detailMenu, idx, mainData, productId, datePreorder, userId, }) => dispatch(actions_createOrder.addMenuToCart({ detailMenu, idx, mainData, productId, datePreorder, userId, })),
    removeMenuFromCart: ({ detailMenu, mainData }) => dispatch(actions_createOrder.removeMenuFromCart({ detailMenu, mainData })),
    checkListDateTime: ({ productId, datePreorder, userId, idx }) => dispatch(actions_createOrder.checkListDateTime({ productId, datePreorder, userId, idx })),
    getListMenu: ({ shop, menu, date, time }) => dispatch(actions_menus.getListMenu({ shop, menu, date, time })),
    setSearch: ({ data, idx }) => dispatch(actions_createOrder.setSearch({ data, idx }))
})
const mapStateToProps = state => ({
    activeTab: state.createOrder.activeTab,
    orderShop: state.createOrder.orderShop,
    createOrder: state.createOrder
})
export default connect(mapStateToProps, mapDispatchToProps)(CardMenu)